import { CALENDAR_EVENT_COLORS } from "../constants";

const buildEventRenderProperties = (type) => {
  const {
    backgroundRed,
    backgroundGreen,
    backgroundYellow,
    fontRed,
    fontGreen,
    fontYellow,
  } = CALENDAR_EVENT_COLORS;

  switch (type) {
    case "closed":
      return {
        backgroundColor: backgroundYellow,
        textColor: fontYellow,
        borderColor: backgroundYellow,
        
      };
    case "cancelled":
      return {
        backgroundColor: backgroundRed,
        textColor: fontRed,
        borderColor: backgroundRed,
      };
    default:
      return {
        backgroundColor: backgroundGreen,
        textColor: fontGreen,
        borderColor: backgroundGreen,
      };
  }
};

export { buildEventRenderProperties };
