const actionTypes = {
  SESSION_REQUEST_LOADING: 'SESSION_REQUEST_LOADING',
  SESSION_REQUEST_SUCCESS: 'SESSION_REQUEST_SUCCESS',
  SESSION_REQUEST_FAILURE: 'SESSION_REQUEST_FAILURE',
  LOGIN_REQUEST_LOADING: 'LOGIN_REQUEST_LOADING',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILURE: 'LOGIN_REQUEST_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  USER_FORGOT_PASSWORD_REQUEST_LOADING: 'USER_FORGOT_PASSWORD_REQUEST_LOADING',
  USER_FORGOT_PASSWORD_REQUEST_SUCCESS: 'USER_FORGOT_PASSWORD_REQUEST_SUCCESS',
  USER_FORGOT_PASSWORD_REQUEST_FAILURE: 'USER_FORGOT_PASSWORD_REQUEST_FAILURE',
  USER_RESET_PASSWORD_REQUEST_LOADING: 'USER_RESET_PASSWORD_REQUEST_LOADING',
  USER_RESET_PASSWORD_REQUEST_SUCCESS: 'USER_RESET_PASSWORD_REQUEST_SUCCESS',
  USER_RESET_PASSWORD_REQUEST_FAILURE: 'USER_RESET_PASSWORD_REQUEST_FAILURE',
  USER_REGISTER_REQUEST_LOADING: 'USER_REGISTER_REQUEST_LOADING',
  USER_REGISTER_REQUEST_SUCCESS: 'USER_REGISTER_REQUEST_SUCCESS',
  USER_REGISTER_REQUEST_FAILURE: 'USER_REGISTER_REQUEST_FAILURE',
  USER_DELETE_REQUEST_LOADING: 'USER_DELETE_REQUEST_LOADING',
  USER_DELETE_REQUEST_SUCCESS: 'USER_DELETE_REQUEST_SUCCESS',
  USER_DELETE_REQUEST_FAILURE: 'USER_DELETE_REQUEST_FAILURE',
  USER_UPDATE_REQUEST_LOADING: 'USER_UPDATE_REQUEST_LOADING',
  USER_UPDATE_REQUEST_SUCCESS: 'USER_UPDATE_REQUEST_SUCCESS',
  USER_UPDATE_REQUEST_FAILURE: 'USER_UPDATE_REQUEST_FAILURE',
  SEND_CONFIRMATION_MAIL_REQUEST_LOADING: 'SEND_CONFIRMATION_MAIL_REQUEST_LOADING',
  SEND_CONFIRMATION_MAIL_REQUEST_SUCCESS: 'SEND_CONFIRMATION_MAIL_REQUEST_SUCCESS',
  SEND_CONFIRMATION_MAIL_REQUEST_FAILURE: 'SEND_CONFIRMATION_MAIL_REQUEST_FAILURE',
  GET_USERS_REQUEST_LOADING: 'GET_USERS_REQUEST_LOADING',
  GET_USERS_REQUEST_SUCCESS: 'GET_USERS_REQUEST_SUCCESS',
  GET_USERS_REQUEST_FAILURE: 'GET_USERS_REQUEST_FAILURE',
  DESTINATION_FETCH_LOADING: 'DESTINATION_FETCH_LOADING',
  DESTINATION_FETCH_SUCCESS: 'DESTINATION_FETCH_SUCCESS',
  DESTINATION_FETCH_FAILURE: 'DESTINATION_FETCH_FAILURE',
  DESTINATION_CREATE_LOADING: 'DESTINATION_CREATE_LOADING',
  DESTINATION_CREATE_SUCCESS: 'DESTINATION_CREATE_SUCCESS',
  DESTINATION_CREATE_FAILURE: 'DESTINATION_CREATE_FAILURE',
  DESTINATION_DELETE_LOADING: 'DESTINATION_DELETE_LOADING',
  DESTINATION_DELETE_SUCCESS: 'DESTINATION_DELETE_SUCCESS',
  DESTINATION_DELETE_FAILURE: 'DESTINATION_DELETE_FAILURE',
  TRANSFERS_FETCH_LOADING: 'TRANSFERS_FETCH_LOADING',
  TRANSFERS_FETCH_SUCCESS: 'TRANSFERS_FETCH_SUCCESS',
  TRANSFERS_FETCH_FAILURE: 'TRANSFERS_FETCH_FAILURE',
  TRANSFER_CREATE_LOADING: 'TRANSFER_CREATE_LOADING',
  TRANSFER_CREATE_SUCCESS: 'TRANSFER_CREATE_SUCCESS',
  TRANSFER_CREATE_FAILURE: 'TRANSFER_CREATE_FAILURE',
  TRANSFER_DELETE_LOADING: 'TRANSFER_DELETE_LOADING',
  TRANSFER_DELETE_SUCCESS: 'TRANSFER_DELETE_SUCCESS',
  TRANSFER_DELETE_FAILURE: 'TRANSFER_DELETE_FAILURE',
  DRIVERS_FETCH_LOADING: 'DRIVER_FETCH_LOADING',
  DRIVERS_FETCH_SUCCESS: 'DRIVER_FETCH_SUCCESS',
  DRIVERS_FETCH_FAILURE: 'DRIVER_FETCH_FAILURE',
  DRIVER_CREATE_LOADING: 'DRIVER_CREATE_LOADING',
  DRIVER_CREATE_SUCCESS: 'DRIVER_CREATE_SUCCESS',
  DRIVER_CREATE_FAILURE: 'DRIVER_CREATE_FAILURE',
  DRIVER_DELETE_LOADING: 'DRIVER_DELETE_LOADING',
  DRIVER_DELETE_SUCCESS: 'DRIVER_DELETE_SUCCESS',
  DRIVER_DELETE_FAILURE: 'DRIVER_DELETE_FAILURE',
  TRAVELS_FETCH_LOADING: 'TRAVELS_FETCH_LOADING',
  TRAVELS_FETCH_SUCCESS: 'TRAVELS_FETCH_SUCCESS',
  TRAVELS_FETCH_FAILURE: 'TRAVELS_FETCH_FAILURE',
  TRAVEL_CREATE_LOADING: 'TRAVEL_CREATE_LOADING',
  TRAVEL_CREATE_SUCCESS: 'TRAVEL_CREATE_SUCCESS',
  TRAVEL_CREATE_FAILURE: 'TRAVEL_CREATE_FAILURE',
  TRAVEL_DELETE_LOADING: 'TRAVEL_DELETE_LOADING',
  TRAVEL_DELETE_SUCCESS: 'TRAVEL_DELETE_SUCCESS',
  TRAVEL_DELETE_FAILURE: 'TRAVEL_DELETE_FAILURE',
  RESERVATIONS_FETCH_LOADING: 'RESERVATIONS_FETCH_LOADING',
  RESERVATIONS_FETCH_SUCCESS: 'RESERVATIONS_FETCH_SUCCESS',
  RESERVATIONS_FETCH_FAILURE: 'RESERVATIONS_FETCH_FAILURE',
  RESERVATION_CREATE_LOADING: 'RESERVATION_CREATE_LOADING',
  RESERVATION_CREATE_SUCCESS: 'RESERVATION_CREATE_SUCCESS',
  RESERVATION_CREATE_FAILURE: 'RESERVATION_CREATE_FAILURE',
  RESERVATION_DELETE_LOADING: 'RESERVATION_DELETE_LOADING',
  RESERVATION_DELETE_SUCCESS: 'RESERVATION_DELETE_SUCCESS',
  RESERVATION_DELETE_FAILURE: 'RESERVATION_DELETE_FAILURE',
  EVENTS_FETCH_LOADING: 'EVENTS_FETCH_LOADING',
  EVENTS_FETCH_SUCCESS: 'EVENTS_FETCH_SUCCESS',
  EVENTS_FETCH_FAILURE: 'EVENTS_FETCH_FAILURE',
  EVENT_STATUS_UPDATE_LOADING: 'EVENT_STATUS_UPDATE_LOADING',
  EVENT_STATUS_UPDATE_SUCCESS: 'EVENT_STATUS_UPDATE_SUCCESS',
  EVENT_STATUS_UPDATE_FAILURE: 'EVENT_STATUS_UPDATE_FAILURE',
  PAYMENTS_GET_BY_RESERVATION_LOADING: 'PAYMENTS_GET_BY_RESERVATION_LOADING',
  PAYMENTS_GET_BY_RESERVATION_SUCCESS: 'PAYMENTS_GET_BY_RESERVATION_SUCCESS',
  PAYMENTS_GET_BY_RESERVATION_FAILURE: 'PAYMENTS_GET_BY_RESERVATION_FAILURE',
  PAYMENT_CREATE_LOADING: 'PAYMENT_CREATE_LOADING',
  PAYMENT_CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
  PAYMENT_CREATE_FAILURE: 'PAYMENT_CREATE_FAILURE',
  PAYMENT_DELETE_LOADING: 'PAYMENT_DELETE_LOADING',
  PAYMENT_DELETE_SUCCESS: 'PAYMENT_DELETE_SUCCESS',
  PAYMENT_DELETE_FAILURE: 'PAYMENT_DELETE_FAILURE',
  REPORT_SEND_REQUEST_LOADING: 'REPORT_SEND_REQUEST_LOADING',
  REPORT_SEND_REQUEST_SUCCESS: 'REPORT_SEND_REQUEST_SUCCESS',
  REPORT_SEND_REQUEST_FAILURE: 'REPORT_SEND_REQUEST_FAILURE',
}

export default actionTypes;