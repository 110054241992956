const initialState = {
  test: 'KLALLLLLLLLLLLLLASDLASDLASLD'
};

const exampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EXAMPLE_ACTION':
      return {
        ...state,
        test:'123123123'
      }
    default:
      return state;
  }
}

export default exampleReducer