import React from 'react'
import {cn, Listbox, ListboxItem } from '@nextui-org/react';
import { faCarSide, faIdCard, faMapLocationDot, faUser, faCompass, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const IconWrapper = ({children, className}) => (
  <div className={cn(className, "flex items-center rounded-small justify-center w-7 h-7")}>
    {children}
  </div>
);

const SideBar = ({setReport, report}) => {
  const getItemClass = (key) => {
    if (key === report) {
      return "bg-primary/10 text-primary";
    }
    return "";
  }
  return (
    <div className="mr-5 ml-2">
      <Listbox
        aria-label="Actions"
        onAction={(key) => setReport(key)}
        selectedKeys={report}
        className="p-0 gap-0 divide-y divide-default-300/50 dark:divide-default-100/80 bg-content1 max-w-[300px] overflow-visible shadow-small rounded-medium"
        itemClasses={{
          base: "px-3 first:rounded-t-medium last:rounded-b-medium rounded-none gap-3 h-12 data-[hover=true]:bg-default-100/80",
        }}
      >
        <ListboxItem
          key="drivers"
          className={getItemClass("drivers")}
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <FontAwesomeIcon icon={faIdCard} />
            </IconWrapper>
          }
        >
          <div className='hidden sm:flex gap-4'>
            Conductores
          </div>
        </ListboxItem>
        <ListboxItem
          className={getItemClass("vehicles")}
          key="vehicles"
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <FontAwesomeIcon icon={faCarSide} />
            </IconWrapper>
          }
        >
          <div className='hidden sm:flex gap-4'>
            Vehiculos
          </div>
        </ListboxItem>
        <ListboxItem
          className={getItemClass("destinations")}
          key="destinations"
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <FontAwesomeIcon icon={faMapLocationDot} />
            </IconWrapper>
          }
        >
          <div className='hidden sm:flex gap-4'>
            Destinos
          </div>
        </ListboxItem>
        <ListboxItem
          className={getItemClass("trips")}
          key="trips"
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <FontAwesomeIcon icon={faCompass} />
            </IconWrapper>
          }
        >
          <div className='hidden sm:flex gap-4'>
            Viajes
          </div>
        </ListboxItem>
        <ListboxItem
          className={getItemClass("reservations")}
          key="reservations"
          startContent={
            <IconWrapper className="bg-primary/10 text-primary">
              <FontAwesomeIcon icon={faCalendarDays} />
            </IconWrapper>
          }
        >
          <div className='hidden sm:flex gap-4'>
            Reservas
          </div>
        </ListboxItem>
      </Listbox>
    </div>
  );
}

export default SideBar;
