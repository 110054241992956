import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingTravel: false,
  errorTravel: false,
  travels: [],
  loadingCreatingTravel: false,
  errorCreatingTravel: false,
  loadingDeletingTravel: false,
  errorDeletingTravel: false,
};

const travelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRAVELS_FETCH_LOADING:
      return {
        ...state,
        loadingTravel: true,
        errorTravel: false,
      };
    case actionTypes.TRAVELS_FETCH_SUCCESS:
      return {
        ...state,
        loadingTravel: false,
        travels: action.payload,
        errorTravel: false,
      };
    case actionTypes.TRAVELS_FETCH_FAILURE:
      return {
        ...state,
        loadingTravel: false,
        errorTravel: true,
      };
    case actionTypes.TRAVEL_CREATE_LOADING:
      return {
        ...state,
        loadingCreatingTravel: true,
        errorCreatingTravel: false,
      };
    case actionTypes.TRAVEL_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreatingTravel: false,
        errorCreatingTravel: false,
      };
    case actionTypes.TRAVEL_CREATE_FAILURE:
      return {
        ...state,
        loadingCreatingTravel: false,
        errorCreatingTravel: true,
      };
    case actionTypes.TRAVEL_DELETE_LOADING:
      return {
        ...state,
        loadingDeletingTravel: true,
        errorDeletingTravel: false,
      };
    case actionTypes.TRAVEL_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletingTravel: false,
        errorDeletingTravel: false,
      };
    case actionTypes.TRAVEL_DELETE_FAILURE:
      return {
        ...state,
        loadingDeletingTravel: false,
        errorDeletingTravel: true,
      };
    default:
      return state;
  }
};

export default travelReducer;
