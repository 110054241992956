import actionTypes from "../../constants/actionTypes";
import supabase from "../../config/supabaseConfig";
import toast from "react-hot-toast";
import { getReservations } from "./reservationAction";

const getPaymentsByReservationLoading = () => ({
  type: actionTypes.PAYMENTS_GET_BY_RESERVATION_LOADING,
});

const getPaymentsByReservationSuccess = (payments) => ({
  type: actionTypes.PAYMENTS_GET_BY_RESERVATION_SUCCESS,
  payload: payments,
});

const getPaymentsByReservationFailure = () => ({
  type: actionTypes.PAYMENTS_GET_BY_RESERVATION_FAILURE,
});

const getPaymentsByReservation = (reservationId) => async (dispatch) => {
  dispatch(getPaymentsByReservationLoading());
  const { data: payments, error } = await supabase
    .from("payments")
    .select()
    .eq("reservation", reservationId);
  
  if (!error) {
    dispatch(getPaymentsByReservationSuccess(payments));
  } else {
    toast.error('Error al procesar la solicitud');
    dispatch(getPaymentsByReservationFailure());
  }
};

const createPaymentLoading = () => ({
  type: actionTypes.PAYMENT_CREATE_LOADING,
});

const createPaymentSuccess = () => ({
  type: actionTypes.PAYMENT_CREATE_SUCCESS,
});

const createPaymentFailure = () => ({
  type: actionTypes.PAYMENT_CREATE_FAILURE,
});

const createPayment =
  (formData = {}, reservationId, successCallback = () => {}) =>
  async (dispatch) => {
    dispatch(createPaymentLoading());

    const { installment, paymentType } = formData;
    if (installment && reservationId && paymentType) {

      try {
        const { data: _, error } = await supabase.from("payments").insert([
          {
            installment: installment,
            reservation: reservationId,
            payment_type: paymentType,
          },
        ]);

        if (!error) {
          dispatch(createPaymentSuccess());
          successCallback();
          dispatch(getPaymentsByReservation(reservationId));
          dispatch(getReservations());

        } else {
          dispatch(createPaymentFailure());
        }
      } catch (error) {
        toast.error("Error al procesar solicitud");
        dispatch(createPaymentFailure());
      }
    } else {
      toast.error("Error de ingreso datos");
      dispatch(createPaymentFailure());
    }
  };

const deletePaymentLoading = () => ({
  type: actionTypes.PAYMENT_DELETE_LOADING,
});

const deletePaymentSuccess = () => ({
  type: actionTypes.PAYMENT_DELETE_SUCCESS,
});

const deletePaymentFailure = () => ({
  type: actionTypes.PAYMENT_DELETE_FAILURE,
});

const deletePayment = (paymentId, successCallback) => async (dispatch) => {
  dispatch(deletePaymentLoading());
  
  const { error } = await supabase
    .from("payments")
    .delete()
    .eq("id", paymentId);
  
  if (!error) {
    dispatch(deletePaymentSuccess());
    successCallback();
  } else {
    toast.error('Error al procesar la solicitud');
    dispatch(deletePaymentFailure());
  }
};



export { createPayment, deletePayment, getPaymentsByReservation}
