// Vendors
import { useEffect }  from 'react'
import moment from 'moment';

//Components
import { Calendar } from '../commons/Calendar';
import CalendarSkeleton from '../commons/CalendarSkeleton';
import CRUDRetryComponent from '../commons/CRUDRetryComponent';

//Hooks
import useEvents from '../../hooks/events/useEvents';

// Mocks
import { eventsArray } from '../../constants/dummies';

// Utilities
import { buildEventRenderProperties } from '../../utilities/eventRenderHelpper';

export function HomeCalendar() {

  const {
    fetchEvents,
    loadingEvent,
    errorEvent,
    events,
    onUpdateEventStatus,
    loadingUpdatingEventStatus,
    errorUpdatingEventStatus,
  } = useEvents();

  useEffect(() => {
    fetchEvents();
  }, [])
  const formatedEvents = events.map((event) => ({
    title: 'Evento',
    start: moment(event.start_date).toDate(),
    end: moment(event.end_date).toDate(),
    display: 'block',
    color: event.status,
    status: event.status,
    destinationInfo: event.destination,
    vehicleInfo: event.vehicle,
    driverInfo: event.driver,
    tripId: event.id,
    reservations: event.reservations,
    ...buildEventRenderProperties(event.status),
  }))

  if (errorEvent) {
    return <CRUDRetryComponent onRetryClick={() => fetchEvents()} type="Viajes" />
  }

  if (loadingEvent) {
    return <CalendarSkeleton />
  }

  return (
    <div className='home-calendar__container'>
      <Calendar
        events={formatedEvents}
        onUpdateEventStatus={onUpdateEventStatus}
        loadingUpdatingEventStatus={loadingUpdatingEventStatus}
        errorUpdatingEventStatus={errorUpdatingEventStatus}
      />
    </div>
  )
}
