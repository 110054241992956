import actionTypes from "../../constants/actionTypes";

const initialState = {
  isLogged: null,
  sessionLoading: false,
  sessionInformation: null,
  userInformation: null,
  publicUserData: null,

  logInLoading: false,
  logInError: false,

  forgotPasswordLoading: false,
  forgotPasswordError: false,
  forgotPasswordSuccess: false,

  resetPasswordLoading: false,
  resetPasswordError: false,

  registerLoading: false,
  registerError: false,

  deleteUserLoading: false,
  deleteUserError: false,

  getUsersLoading: false,
  getUsersError: false,

  sendConfirmationMailLoading: false,
  sendConfirmationMailError: false,

  users: [],
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_UPDATE_REQUEST_LOADING:
    case actionTypes.USER_REGISTER_REQUEST_LOADING:
      return {
        ...state,
        registerLoading: true,
        registerError: false,
      }
    case actionTypes.USER_UPDATE_REQUEST_SUCCESS:
    case actionTypes.USER_REGISTER_REQUEST_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerError: false,
      }
    case actionTypes.USER_UPDATE_REQUEST_FAILURE:
    case actionTypes.USER_REGISTER_REQUEST_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerError: true,
      }
    case actionTypes.SESSION_REQUEST_LOADING:
      return {
        ...state,
        sessionLoading: true,
        userInformation: null
      }
    case actionTypes.SESSION_REQUEST_SUCCESS:
      const session = action.payload.session.session;
      const userData = action.payload.userData.user;
      const publicUserData = action.payload.publicUserData;
      const userRole =
        publicUserData &&
        publicUserData.user_roles &&
        !!publicUserData.user_roles.length
          ? publicUserData.user_roles[0].role
          : "tourist";
      return {
        ...state,
        isLogged: !!session,
        sessionInformation: session,
        sessionLoading: false,
        userInformation: userData,
        publicUserData: {...publicUserData, userRole}
      }
    case actionTypes.SESSION_REQUEST_FAILURE:
      return {
        ...state,
        isLogged: false,
        sessionLoading: false,
      }
    case actionTypes.LOGIN_REQUEST_LOADING:
      return {
        ...state,
        logInLoading: true,
      }
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        logInLoading: false,
        logInError: false,
        isLogged: true,
      };
    case actionTypes.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        logInLoading: false,
        logInError: true,
      }
      case actionTypes.LOGOUT_REQUEST:
        return {
          ...state,
          isLogged: false,
        }
      case actionTypes.USER_FORGOT_PASSWORD_REQUEST_LOADING:
        return {
          ...state,
          forgotPasswordLoading: true,
          forgotPasswordError: false,
          forgotPasswordSuccess: false,
        }
      case actionTypes.USER_FORGOT_PASSWORD_REQUEST_SUCCESS:
        return {
          ...state,
          forgotPasswordLoading: false,
          forgotPasswordError: false,
          forgotPasswordSuccess: true,
        }
      case actionTypes.USER_FORGOT_PASSWORD_REQUEST_FAILURE:
        return {
          ...state,
          forgotPasswordLoading: false,
          forgotPasswordError: true,
          forgotPasswordSuccess: false,
        }
      case actionTypes.USER_RESET_PASSWORD_REQUEST_LOADING:
        return {
          ...state,
          resetPasswordLoading: true,
          resetPasswordError: false,
        }
      case actionTypes.USER_RESET_PASSWORD_REQUEST_SUCCESS:
        return {
          ...state,
          resetPasswordLoading: false,
          resetPasswordError: false,
        }
      case actionTypes.USER_RESET_PASSWORD_REQUEST_FAILURE:
        return {
          ...state,
          resetPasswordLoading: false,
          resetPasswordError: true,
        }
      case actionTypes.GET_USERS_REQUEST_LOADING:
        return {
          ...state,
          getUsersLoading: true,
          getUsersError: false,
        }
      case actionTypes.GET_USERS_REQUEST_SUCCESS:
        return {
          ...state,
          getUsersLoading: false,
          getUsersError: false,
          users: action.payload.data
        }
      case actionTypes.GET_USERS_REQUEST_FAILURE:
        return {
          ...state,
          getUsersLoading: false,
          getUsersError: true,
        }
      case actionTypes.USER_DELETE_REQUEST_LOADING:
        return {
          ...state,
          deleteUserLoading: true,
          deleteUserError: false,
        }
      case actionTypes.USER_DELETE_REQUEST_SUCCESS:
        return {
          ...state,
          deleteUserLoading: false,
          deleteUserError: false,
        }
      case actionTypes.USER_DELETE_REQUEST_FAILURE:
        return {
          ...state,
          deleteUserLoading: false,
          deleteUserError: true,
        }
      case actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_FAILURE:
        return {
          ...state,
          sendConfirmationMailLoading: false,
          sendConfirmationMailError: true,
        }
      case actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_SUCCESS:
        return {
          ...state,
          sendConfirmationMailLoading: false,
          sendConfirmationMailError: false,
        }
      case actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_LOADING:
        return {
          ...state,
          sendConfirmationMailLoading: true,
          sendConfirmationMailError: false,
        }
      default:
        return state;
  }
}

export default sessionReducer