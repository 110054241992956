import { useState } from "react";
import {
  getTravels,
  createTravel,
  updateTravel,
  deleteTravel
} from "../../store/actions/travelsActions";
import { useDispatch, useSelector } from "react-redux";

const useTravels = (travel) => {
  const dispatch = useDispatch();
  const [travelSchema, setTravelSchema] = useState({
    id: travel ? travel.id : "",
    created_at: travel ? travel.created_at : "",
    created_by: travel ? travel.created_by : "",
    destination_id: travel ? travel.destination_id : "",
    vehicle_id: travel ? travel.vehicle_id : "",
    driver_id: travel ? travel.driver_id : "",
    start_date: travel ? travel.start_date : "",
    end_date: travel ? travel.end_date : "",
    seats: travel ? travel.seats : "",
    is_deleted: travel ? travel.is_deleted : false,
    available_seats: travel ? travel.available_seats : "",
    status: travel ? travel.status : "",
    contractor: travel ? travel.contractor : "",
    contractor_type: travel ? travel.contractor_type : ""
  });

  const onSetForm = (type, value) => {
    setTravelSchema({
      ...travelSchema,
      [type]: value,
    });
  };

  const onSetFormValues = (value) => {
    setTravelSchema({
      ...travelSchema,
      ...value,
    });
  };

  const travelsState = useSelector((state) => state.travels);
  const {
    loadingTravel,
    errorTravel,
    travels,
    loadingCreatingTravel,
    errorCreatingTravel,
    loadingDeletingTravel,
    errorDeletingTravel
  } = travelsState;

  const fetchTravel = () => dispatch(getTravels());

  const onCreateNewTravel = (successCallback) =>
    dispatch(createTravel(travelSchema, successCallback));

  const onUpdateTravel = (successCallback) =>
    dispatch(updateTravel(travelSchema, successCallback));

  const onDeleteTravel = (id, successCallback) =>
    dispatch(deleteTravel(id, successCallback));

  return {
    travelSchema,
    onSetForm,
    onSetFormValues,
    travels,
    fetchTravel,
    loadingTravel,
    errorTravel,
    onCreateNewTravel,
    loadingCreatingTravel,
    errorCreatingTravel,
    onUpdateTravel,
    loadingDeletingTravel,
    errorDeletingTravel,
    onDeleteTravel,
  };
};

export default useTravels;
