// Vendors
import React, { useRef } from "react";

// Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import esLocale from "@fullcalendar/core/locales/es";

// Components
import FullCalendar from "@fullcalendar/react";
import { CalendarHeader } from "./CalendarHeader";
import { CalendarEvent } from "./CalendarEvent";
import { CalendarEventModal } from "./CalendarEventModal";
import { useCalendarModal } from "../../hooks/calendar/useCalendarModal";

// Hooks
import { useSessions } from "../../hooks/useSessions";

// Constants
import { TOURIST_ROLE } from "../../constants";

export const Calendar = ({
  events,
  handleEventClick,
  onUpdateEventStatus,
  loadingUpdatingEventStatus,
  errorUpdatingEventStatus,
}) => {
  const calendarRef = useRef();
  const { isOpen, onOpen, onOpenChange, modalInfo } = useCalendarModal();
  const {
    publicUserData: { userRole },
  } = useSessions();

  function renderEventContent(eventInfo) {
    return <CalendarEvent eventInfo={eventInfo} onOpenModal={onOpen} />;
  }

  const isTourist = userRole === TOURIST_ROLE;

  return (
    <>
      <CalendarHeader calendarRef={calendarRef} />
      <CalendarEventModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        eventInfo={modalInfo}
        onUpdateEventStatus={onUpdateEventStatus}
        loadingUpdatingEventStatus={loadingUpdatingEventStatus}
        errorUpdatingEventStatus={errorUpdatingEventStatus}
      />
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={false}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventContent={renderEventContent}
        eventClick={!isTourist && handleEventClick}
        titleFormat={{ year: "numeric", month: "short" }}
        locale={esLocale}
        height="auto"
      />
    </>
  );
};
