import React from 'react'
import { useSessions } from '../../hooks/useSessions'

export const RoleContentHandler = ({ roleList, children }) => {
  const {
    publicUserData: { userRole },
  } = useSessions();
  if (roleList && roleList.includes(userRole)) {
    return <>{children}</>;
  }
};
