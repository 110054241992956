import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";
import {AVATAR_DESTINATION, ADMIN_ROLE} from "../../constants/index"
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";


  export default function CellDestinations({
    destination,
    columnKey,
    editOrCreateDestination,
    deleteDestination,
    positionDestination,
    userRole
  }) {
    const cellValue = destination[columnKey];
    let content;
    switch (columnKey) {
      case "name":
        return (
          <User
            avatarProps={{ radius: "lg", src: AVATAR_DESTINATION }}
            name={cellValue}
          ></User>
        );
      case "observations":
        return (
          <div className="flex">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "particular_price":
        content = destination.particular_price ?? "-";
        return (
          <div className="flex">
            <p className="text-center">{content}</p>
          </div>
        );
      case "enterprise_price":
        content = destination.enterprise_price ?? "-";
        return (
          <div className="flex">
            <p className="text-center">{content}</p>
          </div>
        );
      case "distance":
        content = destination.distance ? `${destination.distance} km` : "-";
        return (
          <div className="flex">
            <p className="">{content}</p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  onPress={() => editOrCreateDestination("view", destination)}
                >
                  Ver
                </DropdownItem>
                {userRole === ADMIN_ROLE && (
                  <DropdownItem
                    onPress={() => editOrCreateDestination("edit", destination)}
                  >
                    Editar
                  </DropdownItem>
                )}
                {userRole === ADMIN_ROLE && (
                  <DropdownItem onPress={() => deleteDestination(destination)}>
                    Eliminar
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }
  