import actionTypes from "../../constants/actionTypes";

const initialState = {
  payments: [],
  getPaymentsLoading: false,
  getPaymentsError: false,
  createPaymentLoading: false,
  createPaymentError: false,
  deletePaymentLoading: false,
  deletePaymentError: false,
};

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENTS_GET_BY_RESERVATION_LOADING:
      return {
        ...state,
        getPaymentsLoading: true,
        getPaymentsError: false,
      };
    case actionTypes.PAYMENTS_GET_BY_RESERVATION_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        getPaymentsLoading: false,
        getPaymentsError: false,
      };
    case actionTypes.PAYMENTS_GET_BY_RESERVATION_FAILURE:
      return {
        ...state,
        getPaymentsLoading: false,
        getPaymentsError: true,
      };
    case actionTypes.PAYMENT_CREATE_LOADING:
      return {
        ...state,
        createPaymentLoading: true,
        createPaymentError: false,
      };
    case actionTypes.PAYMENT_CREATE_SUCCESS:
      return {
        ...state,
        createPaymentLoading: false,
        createPaymentError: false,
      };
    case actionTypes.PAYMENT_CREATE_FAILURE:
      return {
        ...state,
        createPaymentLoading: false,
        createPaymentError: true,
      };
    case actionTypes.PAYMENT_DELETE_LOADING:
      return {
        ...state,
        deletePaymentLoading: true,
        deletePaymentError: false,
      };
    case actionTypes.PAYMENT_DELETE_SUCCESS:
      return {
        ...state,
        deletePaymentLoading: false,
        deletePaymentError: false,
      };
    case actionTypes.PAYMENT_DELETE_FAILURE:
      return {
        ...state,
        deletePaymentLoading: false,
        deletePaymentError: true,
      };
    default:
      return state;
  }
}

export default paymentsReducer;
