import React from "react";
import { Input, Button } from "@nextui-org/react";
import { PlusIcon } from "../commons/icons/PlusIcon";
import { SearchIcon } from "../commons/icons/SearchIcon";
import { RoleContentHandler } from "../commons/RoleContentHandler";
import { ADMIN_ROLE } from "../../constants";

export default function TopContentTableTransfers({
  editOrCreateTransfer,
  onClear,
  onSearchChange,
  filterValue,
}) {
  return (
    <div className="transfers__buttons-principal">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          className="w-full sm:max-w-[44%]"
          placeholder="Buscar por patente..."
          startContent={<SearchIcon />}
          value={filterValue}
          onClear={() => onClear()}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
          <RoleContentHandler roleList={[ADMIN_ROLE]}>
            <Button
              color="primary"
              onPress={() =>
                editOrCreateTransfer("create", {
                  id: "",
                  licensePlate: "",
                  brand:"",
                  model:"",
                  year:"",
                  capacity: "",
                  status: "",
                  observations:""
                })
              }
              endContent={<PlusIcon />}
            >
              Agregar transfer
            </Button>
          </RoleContentHandler>
        </div>
      </div>
    </div>
  );
}
