// Vendors
import React, {useState} from 'react'
import moment from 'moment-timezone';

export const useCalendarHeader = (calendarRef) => {

  const [date, setDate] = useState(
    moment(calendarRef.current?.getApi().getDate()),
  );
  
  const handleCalendarView = (newView) => {
    calendarRef.current?.getApi().changeView(newView)
  } 

  const handleDateChange = (direction) => {
    const callApi = calendarRef.current?.getApi();
    if (callApi) {
      if (direction === 'prev') {
        callApi.prev();
      } else if (direction === 'next') {
        callApi.next();
      } else {
        callApi.today();
      }
      setDate(moment(callApi.getDate()));
    }
  };

  return {
    handleCalendarView,
    handleDateChange
  }
}
