// Vendors
import React from "react";

// Components
import { Input, Button } from "@nextui-org/react";
import {Textarea, Select, SelectItem, Spinner, Switch } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

// Constants
import { STATUS_OPTIONS_TRANSFERS } from "../../constants";

// Hooks
import useTransfers from "../../hooks/transfers/useTransfers";

// Utilities
import { areInputsInvalid } from "../../utilities/formValidatorHelpper";

export default function FormCrudTransfers({
  readOnly,
  transfer,
  onClose,
  transferAction,
}) {

  const {
    onSetForm,
    onCreateNewTransfer,
    onUpdateTransfer,
    loadingCreatingTransfer,
    errorCreatingTransfer,
    transferSchema,
  } = useTransfers(transfer);
  const handlePress = () => {
    transferAction === 'edit' ? onUpdateTransfer(onClose) : onCreateNewTransfer(onClose);
  };

  const errorMessage = errorCreatingTransfer ? (
    <div className="text-[#f31260]">
      <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "#f31260",}} />
      <span className="ml-1">
        Error al realizar la operacion
      </span>
    </div>
  ) : null;

  const inputsAreInvalid = areInputsInvalid(
    [
      "licensePlate",
      "brand",
      "model",
      "year",
      "capacity",
      "status",
      "observations",
    ],
    transferSchema
  );

  return (
    <div id="root">
      <Input
        readOnly={readOnly}
        value={transferSchema.licensePlate}
        onChange={(event) => onSetForm("licensePlate", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Patente"
      />

      <Input
        readOnly={readOnly}
        value={transferSchema.brand}
        onChange={(event) => onSetForm("brand", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Marca"
      />

      <Input
        readOnly={readOnly}
        value={transferSchema.model}
        onChange={(event) => onSetForm("model", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Modelo"
      />

      <Input
        readOnly={readOnly}
        value={transferSchema.year}
        onChange={(event) => onSetForm("year", event.target.value)}
        className="abm-form__form-input"
        type="number"
        variant="bordered"
        label="Año"
      />

      <Input
        readOnly={readOnly}
        value={transferSchema.capacity}
        onChange={(event) => onSetForm("capacity", event.target.value)}
        className="abm-form__form-input"
        type="number"
        variant="bordered"
        label="Capacidad Máxima"
      />

      <Select
        variant="bordered"
        label="Estado del vehiculo"
        className="abm-form__form-input"
        placeholder="Selecione el estado actual del vehiculo"
        value={transferSchema.status}
        isDisabled={readOnly}
        onChange={(event) => onSetForm("status", event.target.value)}
        defaultSelectedKeys={[transferSchema.status]}
      >
        {STATUS_OPTIONS_TRANSFERS.map((item) => (
          <SelectItem key={item.uid} value={item.uid} >
            {item.name}
          </SelectItem>
        ))}
      </Select>

      <Textarea
        readOnly={readOnly}
        defaultValue=" "
        label="Observación"
        value={transferSchema.observations}
        onChange={(event) => onSetForm("observations", event.target.value)}
        type="text"
        variant="bordered"
      />

      <div className="flex flex-col gap-2">
        <Switch
          isSelected={transferSchema.suitableForDisabledPeople}
          isDisabled={readOnly}
          onValueChange={(value) =>
            onSetForm("suitableForDisabledPeople", value)
          }
        >
          Vehiculo apto para personas con discapacidad: {transferSchema.suitableForDisabledPeople ? "SI" : "NO"}
        </Switch>
      </div>

      <div>{errorMessage}</div>

      <div className="flex flex-col gap-1 w-full">
        <div className="transfers__save-button">
          <Button color="danger" variant="light" onPress={onClose}>
            Cerrar
          </Button>
          {!readOnly && (
            <Button
              color="primary"
              onPress={handlePress}
              isLoading={loadingCreatingTransfer}
              isDisabled={loadingCreatingTransfer || inputsAreInvalid}
              spinner={<Spinner size="sm" />}
            >
              Guardar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
