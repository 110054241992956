import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody
} from "@nextui-org/react";
import FormFormCrudUsersAbm from "../users/FormCrudUsers";

export default function ModalUserData({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  userSelected,
  formUser,
  setForm,
  onSendForm,
  registerLoading,
  registerError,
  userAction,
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titileModel}
            </ModalHeader>
            <ModalBody>
              <div>
                <FormFormCrudUsersAbm
                  user={userSelected}
                  readOnly={fieldFlagReadOnly}
                  onClose={onClose}
                  formUser={formUser}
                  setForm={setForm}
                  onSendForm={onSendForm}
                  registerLoading={registerLoading}
                  registerError={registerError}
                  userAction={userAction}
                />
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
