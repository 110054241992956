import { Routes, Route, useSession } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Home from "../views/Home";
import LogIn from "../views/LogIn";
import Transfers from "../views/Transfers";
import Users from "../views/Users";
import Destinations from "../views/Destinations"
import Travels from "../views/Travels";
import Drivers from "../views/Drivers";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword"; 
import Reservations from "../views/Reservations";
import Reports from "../views/Reports";

function Router() {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/inicio" element={<Home />} />
          <Route path="/users" element={<Users />} exact />
          <Route path="/transfers" element={<Transfers/>} exact />
          <Route path="/destinos" element={<Destinations/>} exact />
          <Route path="/viajes" element={<Travels/>} exact />
          <Route path="/conductores" element={<Drivers/>} exact />
          <Route path="/reservas/:id" element={<Reservations/>} exact />
          <Route path="/reports" element={<Reports />} exact/>
        </Route>
        <Route path="/reset-password" element={<ResetPassword/>} exact/> 
        <Route path="/login" element={<LogIn />} exact />
        <Route path="/forgot-password" element={<ForgotPassword/>} exact/>
      </Routes>
    </div>
  );
}

export default Router;
