
import actionTypes from "../../constants/actionTypes";
import supabase from "../../config/supabaseConfig";

const getEventsLoading = () => ({
  type: actionTypes.EVENTS_FETCH_LOADING,
});

const getEventsSuccess = (data) => ({
  type: actionTypes.EVENTS_FETCH_SUCCESS,
  payload: data,
});

const getEventsFailure = () => ({
  type: actionTypes.EVENTS_FETCH_FAILURE,
});

const getEvents = () => async (dispatch) => {
  dispatch(getEventsLoading());
  let { data, error } = await supabase.from("trip").select(
    `*,
    destination(*),
    vehicle(*),
    driver(*),
    start_date,
    end_date,
    status,
    reservations(*)
    `);
    if (!error) {
      dispatch(getEventsSuccess(data));
    } else {
      dispatch(getEventsFailure());
    }
  
} 

const updateEventStatusLoading = () => ({
  type: actionTypes.EVENT_STATUS_UPDATE_LOADING,
});

const updateEventStatusSuccess = () => ({
  type: actionTypes.EVENT_STATUS_UPDATE_SUCCESS,
});

const updateEventStatusFailure = () => ({
  type: actionTypes.EVENT_STATUS_UPDATE_FAILURE,
});

const updateEventStatus = (eventId, newStatus, successCallback) => async (dispatch) => {
  dispatch(updateEventStatusLoading());

  const { error } = await supabase
    .from("trip")
    .update({ status: newStatus }) 
    .eq("id", parseInt(eventId))
    .select();

  if (!error) {
    dispatch(updateEventStatusSuccess());
    successCallback();
    dispatch(getEvents());
  } else {
    dispatch(updateEventStatusFailure());
  }
}

export { getEvents, updateEventStatus };