// Vendors
import React, { useEffect } from "react";

// Components
import ModalDestinationsData from "../components/destinations/ModalDestinationsData";
import TableDestinations from "../components/destinations/TableDestinations";
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import CRUDRetryComponent from "../components/commons/CRUDRetryComponent";
import { Toaster } from "react-hot-toast";
import RoleViewHandler from "../components/commons/RoleViewHandler";

// Hooks
import { useDisclosure } from "@nextui-org/react";
import useDestinations from "../hooks/destinations/useDestinations";
import ModalDelete from "../components/commons/ModalDelete"


// Constants
import { ADMIN_ROLE, TRANSFER_ROLE } from '../constants';

export default function Destinations() {
  const {
    fetchDestination,
    loadingDestination,
    errorDestination,
    onDeleteDestination,
    loadingDeletingDestination,
    errorDeletingDestination
  } = useDestinations();

  useEffect(() => {
    fetchDestination();
  }, []);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen , onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const [destinationAction, setValueDestinationAction] = React.useState("");
  
  const titileModel =
    destinationAction === "create"
      ? "Crear destino"
      : destinationAction === "edit"
      ? "Editar destino"
      : "Destino";
  let fieldFlagReadOnly = true;
  if (destinationAction === "create" || destinationAction === "edit") {
    fieldFlagReadOnly = false;
  }

  const [destinationSelected, setDestinationSelected] = React.useState({
    id:'',
    name:'',
    observations:'',
  });

  if (loadingDestination) {
    return <CRUDSkeleton />
  }

  if (errorDestination) {
    return <CRUDRetryComponent type={'destinos'} onRetryClick={fetchDestination}/>;
  }

  return (
    <RoleViewHandler roleList={[ADMIN_ROLE, TRANSFER_ROLE]}>
      <Toaster position="top-center" />
      <ModalDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titileModel={titileModel}
        element={destinationSelected.id}
        onDelete={onDeleteDestination}
        isLoading={loadingDeletingDestination}
        isInvalid={errorDeletingDestination}
      />
      <ModalDestinationsData
        isOpen={isOpen}
        onClose={onClose}
        titileModel={titileModel}
        fieldFlagReadOnly={fieldFlagReadOnly}
        destinationSelected={destinationSelected}
        destinationAction={destinationAction}
      />
      <TableDestinations
        setDestinationSelected={setDestinationSelected}
        setValueDestinationAction={setValueDestinationAction}
        onOpen={onOpen}
        onDeleteOpen={onDeleteOpen}
      />
    </RoleViewHandler>
  );
}
