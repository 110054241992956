// Vendors
import React from 'react'

// Components
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Spinner
} from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

// Hooks
import { useSessions } from '../hooks/useSessions';

const ForgotPassword = () => {
  const {
    forgotPasswordLoading,
    forgotPasswordSuccess,
    forgotPasswordError,
    forgotPasswordMail,
    forgotPasswordAction,
    setForgotPasswordMail,
  } = useSessions();

  const cardContent = forgotPasswordSuccess ? (
    <CardBody className='p-8'>
      <div className='flex items-center justify-center mb-6'>
        <FontAwesomeIcon icon={faCircleCheck} size="6x" style={{color: "#c1e9cd"}} />
      </div>
      <h2 className='text-4xl text-center mb-2'>Listo!</h2>
      <div className='text-sm text-center'>
        <p>Enviamos el correo a <strong>{forgotPasswordMail}</strong></p>
        <p>Puedes cerrar esta ventana y dirigirte a tu casilla de correo</p>
        <p>En caso de no encontrar el correo, por favor, revisa la casilla de "no deseados" o "spam"</p>
      </div>
    </CardBody>
  ) : (
    <>
      <CardHeader className="flex justify-center">
        <h1>Olvidaste tu contraseña</h1>
      </CardHeader>
      <CardBody>
        <p className="mb-5">Necesitamos que nos proporciones tu Email</p>
        <Input
          isDisabled={forgotPasswordLoading}
          isInvalid={forgotPasswordError}
          value={forgotPasswordMail}
          onChange={(e) => setForgotPasswordMail(e.target.value)}
          className="mb-5"
          label="Email"
          variant="bordered"
        />
        <Button
          size="lg"
          color="primary"
          spinner={<Spinner size="sm" />}
          isLoading={forgotPasswordLoading}
          onClick={() => forgotPasswordAction()}
        >
          Continuar
        </Button>
      </CardBody>
    </>
  );

  return (
    <main className="forgot-password__container flex items-center justify-center">
      <section>
        <Card className="forgot-password__card">
          {cardContent}
        </Card>
      </section>
    </main>
  );
}

export default ForgotPassword;
