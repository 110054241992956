// Vendors
import React, { useState } from "react";
import { useSessions } from "../../hooks/useSessions";
import { useLogInForm } from "../../hooks/useLogInForm";

// Components
import { Input, Button, Spinner, Card, CardBody, Spacer } from "@nextui-org/react";

// Hooks
import { useNavigate } from "react-router-dom";

// Assets
import logo from "../../assets/images/icons/marca_marga.png";

export default function LogInForm() {
  const { logInForm, setForm  } = useLogInForm();
  const { logInLoading, logInError, onLogIn } = useSessions();
  const navigate = useNavigate();

  const errorCard = logInError ? (
    <Card className="bg-rose-600 text-white border-danger">
      <CardBody>
        <p>Error! Por favor, vuelva a intentarlo</p>
      </CardBody>
    </Card>
  ) : null;

  return (
    <div className="login-form__box">
      <img className="login-form__logo" src={logo} alt="logo"/>
      <h1 className="login-form__title">Bienvenido! Ingrese su usuario</h1>
      <div className="login-form__form-container">
        {errorCard}
        <Spacer y={5}/>
        <Input
          onChange={(e) => setForm("email", e.target.value)}
          className="login-form__form-input"
          type="text"
          variant="bordered"
          label="Usuario"
          isInvalid={logInError}
          isDisabled={logInLoading}
        />
        <Input
          onChange={(e) => setForm("password", e.target.value)}
          className="login-form__form-input"
          type="password"
          variant="bordered"
          label="Contrase&ntilde;a"
          isInvalid={logInError}
          isDisabled={logInLoading}
        />
        <Button
          onClick={() => onLogIn(logInForm)}
          className="login-form__form-button"
          variant="bordered"
          color="primary"
          isLoading={logInLoading}
          isDisabled={logInLoading}
          spinner={
            <Spinner size="sm"/>
          }
        >
          Ingresar
        </Button>
      </div>
      <div className="login-form__forgot-your-password">
        <p onClick={()=> navigate('/forgot-password')}>¿Olvidaste tu Contraseña?</p>
      </div>
    </div>
  );
}
