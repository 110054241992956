import { useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  DatePicker,
  Checkbox,
} from "@nextui-org/react";
import { now, getLocalTimeZone } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";

import * as FileSaver from "file-saver";

const ReportForm = ({items, title, setForm, reportSchema, makeReport, errorReport, loadingReport}) => {
  const saveAsExcelFile = (buffer, fileName) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  };


  const exportExcel = async () => {
    const data = [{ key1: "hola", key2: "hola2" }];
    const worksheet = await import("xlsx").then((xlsx) =>
      xlsx.utils.json_to_sheet(data)
    );
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer = await import("xlsx").then((xlsx) =>
      xlsx.write(workbook, { bookType: "xlsx", type: "array" })
    );
    saveAsExcelFile(excelBuffer, "drivers");
  };
  let errorMessage;
  if (errorReport) {
    errorMessage = (
      <p className="text-red-500 m-2">Error al generar el reporte, Por favor revisa que los datos ingresados esten correctos. </p>
    );
  }

  const toogleMetrics = (metricId) => {
    const newMetrics = [...reportSchema.metrics];
    const metricIndex = newMetrics.indexOf(metricId);
    if (metricIndex === -1) {
      newMetrics.push(metricId);
    } else {
      newMetrics.splice(metricIndex, 1);
    }
    setForm("metrics", newMetrics);
  };
  const createReport = () =>{
    makeReport(items);
  }
  return (
    <Card className="overflow-visible">
      <CardHeader>
        <h4 className="font-medium text-large">{title}</h4>
      </CardHeader>
      <CardBody className="flex flex-col gap-4 overflow-visible">
        <h5>Por favor seleccione las Metricas</h5>
        <div>
          {items.map((item) => (
            <Checkbox
              className="mr-2"
              isSelected={!!reportSchema?.metrics?.find((m) => m == item.id)}
              onValueChange={() => toogleMetrics(item.id)}
            >
              {item.label}
            </Checkbox>
          ))}
        </div>
        <div className="flex flex-col md:flex-row flex-between w-full">
          <div className="mr-1 w-full">
            <label>Desde</label>
            <I18nProvider locale="es-ES">
              <DatePicker
                value={reportSchema.from}
                calendarWidth={400}
                hideTimeZone
                onChange={(value) => {
                  setForm("from", value);
                }}
                defaultValue={now(getLocalTimeZone())}
                isLoading={loadingReport}
                isDisabled={loadingReport}
              />
            </I18nProvider>
          </div>
          <div className="w-full">
            <label>Hasta</label>
            <I18nProvider locale="es-ES">
              <DatePicker
                className=""
                required={true}
                onChange={(value) => {
                  setForm("to", value);
                }}
                value={reportSchema.to}
                hideTimeZone
                defaultValue={now(getLocalTimeZone())}
                isLoading={loadingReport}
                isDisabled={loadingReport}
              />
            </I18nProvider>
          </div>
        </div>
        {errorMessage}
        <Button
          color="primary"
          onPress={createReport}
          className="text-[#2E2824]"
          fullWidth
          isLoading={loadingReport}
          isDisabled={loadingReport}
        >
          Generate report
        </Button>
      </CardBody>
    </Card>
  );
};

export default ReportForm;
