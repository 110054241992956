import {useState} from 'react'
import { useDispatch, useSelector } from "react-redux";

import { now, getLocalTimeZone } from "@internationalized/date";
import { sendReport } from "../../store/actions/reportAction";

import { REPORT_DRIVER_ITEMS } from "../../constants";

const useReports = () => {
  const dispatch = useDispatch();
  const [reportSchema, setReportSchema] = useState({
    report: "drivers",
    metrics: REPORT_DRIVER_ITEMS.map(i => i.id),
    from: now(getLocalTimeZone()),
    to: now(getLocalTimeZone()),
  })

  const setForm = (type, value) => {
    setReportSchema(prevState  => ({
      ...prevState,
      [type]: value,
    }));
  };

  const makeReport = (items) => {
    dispatch(sendReport(reportSchema, items));
  }

  const reportState = useSelector((state) => state.reports);
  const {
    loadingReport,
    errorReport,
    report,
  } = reportState;

  return {
    reportSchema,
    setForm,
    makeReport,
    loadingReport,
    errorReport,
    report,
  };
}

export default useReports;