import React, {useState} from 'react'

//Components
import RoleViewHandler from "../components/commons/RoleViewHandler";
import SideBar from "../components/reports/SideBar";
import ReportForm from "../components/reports/ReportForm";

//Hooks
import useReports from "../hooks/reports/useReports";

//Constants
import {
  ADMIN_ROLE,
  REPORT_DRIVER_ITEMS,
  REPORT_VEHICLE_ITEMS,
  REPORT_DESTINATION_ITEMS,
  REPORT_TRIP_ITEMS,
  REPORT_RESERVATION_ITEMS,
} from "../constants";


const Reports = () => {
  const {
    reportSchema,
    setForm,
    makeReport,
    loadingReport,
    errorReport,
    report,
  } = useReports();
  const buildReport = (report) => {
    let items, title;
    switch (report) {
      case 'drivers':
        items = REPORT_DRIVER_ITEMS;
        title = 'Reporte de conductores';
        break;
      case 'vehicles':
        items = REPORT_VEHICLE_ITEMS;
        title = 'Reporte de vehiculos';
        break;
      case 'destinations':
        items = REPORT_DESTINATION_ITEMS;
        title = 'Reporte de destinos';
        break;
      case 'reservations':
        items = REPORT_RESERVATION_ITEMS;
        title = 'Reporte de reservas';
        break;
      case 'trips':
      default:
        items = REPORT_TRIP_ITEMS;
        title = 'Reporte de viajes';
        break;
      }
    return (
      <ReportForm
        items={items}
        title={title}
        setForm={setForm}
        reportSchema={reportSchema}
        makeReport={makeReport}
        errorReport={errorReport}
        loadingReport={loadingReport}
      />
    );
  };

  const getNewMetricsByDefault = (newValue) => {
    let items;
    switch (newValue) {
      case 'drivers':
        items= REPORT_DRIVER_ITEMS;
        break
      case 'vehicles':
        items = REPORT_VEHICLE_ITEMS;
        break
      case 'destinations':
        items = REPORT_DESTINATION_ITEMS;
        break
      case 'reservations':
        items = REPORT_RESERVATION_ITEMS;
        break
      case 'trips':
      default:
        items = REPORT_TRIP_ITEMS;
        break;
    }
    return items.map(i => i.id);
  }
  return (
    <RoleViewHandler roleList={[ADMIN_ROLE]}>
      <div className="flex justify-center">
        <div className="max-w-6xl w-full mt-4 mb-4 flex">
          <SideBar
            report={reportSchema.report}
            setReport={(newValue) => {
              setForm("report", newValue);
              setForm("metrics", getNewMetricsByDefault(newValue));
            }}
          />
          <div className="w-3/4">{buildReport(reportSchema.report)}</div>
        </div>
      </div>
    </RoleViewHandler>
  );
};

export default Reports