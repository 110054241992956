// Vendors
import React, { useEffect } from "react";

// Components
import ModalTravelsData from "../components/travels/ModalTravelsData";
import TableTravels from "../components/travels/TableTravels";
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import ModalDelete from "../components/commons/ModalDelete";
import RoleViewHandler from "../components/commons/RoleViewHandler";
import { useNavigate } from "react-router-dom";

// Hooks
import { useDisclosure } from "@nextui-org/react";
import useTransfers from "../hooks/transfers/useTransfers";
import useTravels from "../hooks/travels/useTravels";
import useEvents from "../hooks/events/useEvents";
import useDestinations from "../hooks/destinations/useDestinations";
import useDrivers from "../hooks/drivers/useDrivers";



//Constants
import { ADMIN_ROLE, TRANSFER_ROLE } from "../constants";

export default function Travels() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [travelAction, setValueTravelAction] = React.useState("");
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const {
    travels,
    fetchTravel,
    loadingTravel,
    errorTravel,
    errorDeletingTravel,
    loadingDeletingTravel,
    onDeleteTravel,
  } = useTravels();

  const { transfers, fetchTransfer, loadingTransfer, errorTransfer } =
    useTransfers();
  const { destinations, fetchDestination, loadingDestination, errorDestination } =
    useDestinations();
  const { drivers, fetchDriver, loadingDriver, errorDriver } =
    useDrivers();
  const {
    fetchEvents,
    loadingEvent,
    errorEvent,
    events,
    onUpdateEventStatus,
    loadingUpdatingEventStatus,
    errorUpdatingEventStatus,
  } = useEvents();

  useEffect(() => {
    fetchTravel()
    fetchEvents();
    fetchTransfer();
    fetchDestination();
    fetchDriver();
  }, []);

  const [travelSelected, setTravelSelected] = React.useState({
    id: "",
    destination_id: "",
    vehicle_id: "",
    driver_id: "",
    created_by: "",
    start_date: "",
    end_date: "",
  });
  if (loadingEvent) {
    return <CRUDSkeleton />;
  }

  let onlyPassengers = false;
  let showReservations = false;
  const titileModel =
    travelAction === "create"
      ? "Crear viaje"
      : travelAction === "edit"
      ? "Editar viaje"
      : travelAction == "addpassangers"
      ? "Agregar reserva"
      : travelAction == "showreservations"
      ? "Reservas"
      : "Viaje";
  let fieldFlagReadOnly = true;
  if (travelAction === "create" || travelAction === "edit") {
    fieldFlagReadOnly = false;
  } else if (travelAction == "addpassangers") {
    onlyPassengers = true;
  } else if (travelAction == "showreservations") {
    onlyPassengers = true;
    showReservations = true;
  } else if (travelAction == "link") {
    navigate("/reservas/" + travelSelected.id);
  }
  return (
    <RoleViewHandler roleList={[ADMIN_ROLE, TRANSFER_ROLE]}>
      <ModalDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titileModel={titileModel}
        element={travelSelected}
        onDelete={onDeleteTravel}
        isLoading={loadingDeletingTravel}
        isInvalid={errorDeletingTravel}
      />
      <ModalTravelsData
        isOpen={isOpen}
        onClose={onClose}
        titileModel={titileModel}
        fieldFlagReadOnly={fieldFlagReadOnly}
        travelSelected={travelSelected}
        travelAction={travelAction}
        onlyPassengers={onlyPassengers}
        showReservations={showReservations}
      />
      <TableTravels
        setTravelSelected={setTravelSelected}
        setValueTravelAction={setValueTravelAction}
        onOpen={onOpen}
        onOpenDelete={onDeleteOpen}
      />
    </RoleViewHandler>
  );
}
