import React from "react";
import { formatDate, formatDateToEnd } from "../../utilities/dateHelpper";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "@nextui-org/react";
import CalendarEventModalAttendees from "./CalendarEventModalAttendees";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPenSquare, faLock, faUnlock, faBan } from '@fortawesome/fontawesome-free-solid'
import { RoleContentHandler } from "./RoleContentHandler";
import { ADMIN_ROLE, TRANSFER_ROLE } from '../../constants';
import { useNavigate } from "react-router-dom";

const buildBlockButton = (
  status,
  onClose,
  onUpdateEventStatus,
  loadingUpdatingEventStatus,
  tripId
) => {
  const generateButton = (color, text, icon, newStatus) => (
    <Button
      className="ml-1"
      color={color}
      onClick={() => onUpdateEventStatus(tripId, newStatus, onClose)}
      spinner={<Spinner size="sm" />}
      isLoading={loadingUpdatingEventStatus}
    >
      {text}
      <FontAwesomeIcon icon={icon} />
    </Button>
  );

  let blockButton;
  switch (status) {
    case 'closed':
      blockButton = (
        <>
          {generateButton("success", "Desbloquear viaje", faUnlock, "open")}
          {generateButton("danger", "Cancelar viaje", faBan, "cancelled")}
        </>
      );
      break;
    case 'open':
      blockButton = (
        <>
          {generateButton("warning", "Bloquear viaje", faLock, "closed")}
          {generateButton("danger", "Cancelar viaje", faBan, "cancelled")}
        </>
      );
      break;
    default:
      blockButton = (
        <>
          {generateButton("success", "Reabrir viaje", faUnlock, "open")}
          {generateButton("warning", "Reabrir viaje y bloquear", faLock, "closed")}
        </>
      );
  }
  return blockButton;
};

export const CalendarEventModal = ({
  eventInfo,
  isOpen,
  onOpenChange,
  onUpdateEventStatus,
  loadingUpdatingEventStatus,
  errorUpdatingEventStatus,
}) => {
  const navigate = useNavigate();
  if (!eventInfo?.event?._def?.extendedProps) {
    return (
      <Modal
        isOpen={isOpen}
        placement="auto"
        backdrop="opaque"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Error!</ModalHeader>
              <ModalBody>
                <p>Error recuperando la información del evento</p>
              </ModalBody>
              <ModalFooter>
                <Button color="warning" onPress={() => onClose()}>
                  Cerrar
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    );
  }

  const {
    start: startDate,
    end: endDate,
    _def: {
      extendedProps: {
        destinationInfo,
        driverInfo,
        vehicleInfo,
        reservations,
        tripId,
        status,
      },
    },
  } = eventInfo.event;
  const startFormattedDate = formatDate(startDate);
  const endFormattedDate = endDate
    ? formatDate(endDate)
    : formatDateToEnd(startDate);
  const modalTitle = `ID: ${tripId} - ${destinationInfo.name}`;
  const driverName = `${driverInfo.firstname} ${driverInfo.lastname}`;
  const privateDataPermissionList = [ADMIN_ROLE, TRANSFER_ROLE];
  const vehicleModel = `${vehicleInfo.brand} ${vehicleInfo.model} ${vehicleInfo.year}`;
  const disabledPeopleAvailable = vehicleInfo.suitable_for_disabled_people
    ? "SI"
    : "NO";
  const tripAttendeers = reservations
    ? reservations.reduce((listaUnida, viaje) => {
        return listaUnida.concat(viaje.passenger_list);
      }, [])
    : [];
    
    let blockButton;
    if (status === 'closed') {
      blockButton = (
        <Button className="ml-1" color="success">
          Desbloquear viaje
          <FontAwesomeIcon icon={faUnlock} />
        </Button>
      );
    } else if (status === 'open') {
      blockButton = (
        <Button className="ml-1" color="warning">
          Bloquear viaje
          <FontAwesomeIcon icon={faLock} />
        </Button>
      )
    } else {
      blockButton = (
        <Button className="ml-1" color="success">
          Reabrir viaje
          <FontAwesomeIcon icon={faUnlock} />
        </Button>
      );
    }

  return (
    <Modal
      isOpen={isOpen}
      placement="auto"
      backdrop="opaque"
      onOpenChange={onOpenChange}
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 font-black text-xl capitalize">
              <div className="flex justify-center items-center">
                {modalTitle}
              </div>
            </ModalHeader>
            <ModalBody>
              <div>
                <p className="font-bold text-lg">Horario y destino</p>
                <div className="flex align-center justify-left">
                  <div>{startFormattedDate}</div>
                  <FontAwesomeIcon
                    className="mt-1 mx-3"
                    icon={faArrowRight}
                    size="md"
                  />
                  <div>{endFormattedDate}</div>
                </div>
                <div>
                  <div className="text-m">
                    <span className="font-semibold">Destino:</span>{" "}
                    {destinationInfo.name}
                  </div>
                  <RoleContentHandler roleList={privateDataPermissionList}>
                    <div className="text-m">
                      <span className="font-semibold">
                        Observaciones del destino:{" "}
                      </span>
                      {destinationInfo.observations}
                    </div>
                  </RoleContentHandler>
                </div>
              </div>
              <div>
                <p className="font-bold text-lg">Conductor</p>
                <div>
                  <div className="text-m">
                    <span className="font-semibold">Nombre completo: </span>{" "}
                    {driverName}
                  </div>
                  <div className="text-m">
                    <span className="font-semibold">E-mail: </span>{" "}
                    {driverInfo.email}
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold text-lg">Vehiculo</p>
                <div>
                  <div className="text-m">
                    <span className="font-semibold">Patente: </span>{" "}
                    {vehicleInfo.license_plate}
                  </div>
                  <div className="text-m">
                    <span className="font-semibold">Modelo: </span>{" "}
                    {vehicleModel}
                  </div>
                  <div className="text-m">
                    <span className="font-semibold">
                      Disponibilidad para personas con discapacidad:
                    </span>{" "}
                    {disabledPeopleAvailable}
                  </div>
                  <RoleContentHandler roleList={privateDataPermissionList}>
                    <div className="text-m">
                      <span className="font-semibold">
                        Estado del vehiculo:
                      </span>{" "}
                      {vehicleInfo.status}
                    </div>
                    <div className="text-m">
                      <span className="font-semibold">
                        Observaciones del vehiculo:
                      </span>{" "}
                      {vehicleInfo.observations}
                    </div>
                  </RoleContentHandler>
                </div>
              </div>
              <div className="flex">
                <Button
                  color="success"
                  onPress={() => navigate("/reservas/" + tripId)}
                >
                  Ver Reservas
                  <FontAwesomeIcon icon={faPenSquare} />
                </Button>
                {buildBlockButton(status, onClose, onUpdateEventStatus, loadingUpdatingEventStatus, tripId)}
              </div>
              <CalendarEventModalAttendees attendees={tripAttendeers} />
            </ModalBody>
            <ModalFooter>
              <Button color="warning" onPress={() => onClose()}>
                Cerrar
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
