import React from 'react'
import { Card, CardBody, CardFooter, Image, Button} from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


const CRUDRetryComponent = ({type, onRetryClick}) => {
  return (
    <main className='flex items-center justify-center'>
      <Card className='max-w-[1024px] px-6 m-10'>
        <CardBody>
          <div className='flex items-center justify-center my-6'>
            <FontAwesomeIcon icon={faCircleXmark} size="6x" style={{color: "#f31260"}} />
          </div>
          <div>
            <h2 className='text-4xl text-center mb-2'>Error</h2>
            <div className='text-sm text-center'>
              <p>Error al cargar los datos de {type}. Por favor, intentalo de nuevo!</p>
              <p>Si el problema persiste, revise su conexion a internet</p>
              <p>Si lo anterior no funciono, por favor contacte a el equipo de desarrollo</p>
            </div>
          </div>
        </CardBody>
        <CardFooter className='flex justify-center'>
          <Button className='mb-6 bg-[#f31260] text-white' onClick={onRetryClick}>Volver a intentarlo</Button>
        </CardFooter>
      </Card>
    </main>
  )
}

export default CRUDRetryComponent;