//Constants
import {
  ADMIN_ROLE,
  TRANSFER_ROLE,
  TOURIST_ROLE,
  ADMIN_MENU,
  TRANSFER_MENU,
  TOURIST_MENU,
} from "../constants";

const getUserMenu = (userRole) => {
  switch (userRole) {
    case ADMIN_ROLE:
      return ADMIN_MENU;
    case TRANSFER_ROLE:
      return TRANSFER_MENU;
    case TOURIST_ROLE:
    default:
      return TOURIST_MENU;
  }
};

const isAdmin = (userRole) =>{
  switch (userRole) {
    case ADMIN_ROLE:
      return true;
    default:
      return false;
  }
}

const getUserItemRole = (userItem) => {
  const userRole = userItem?.user_roles?.[0]?.role;
  switch (userRole) {
    case ADMIN_ROLE:
      return "Administrador"
    case TRANSFER_ROLE:
      return "Transfer"
    case TOURIST_ROLE:
      return "Turista"
    default:
      return "-"
  }
}

export { getUserMenu, isAdmin, getUserItemRole };
