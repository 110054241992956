import React, { useEffect } from "react";
import { Button } from "@nextui-org/react";
import useTransfers from "../../hooks/transfers/useTransfers";
import useTravels from "../../hooks/travels/useTravels";
import useDrivers from "../../hooks/drivers/useDrivers";
import useDestinations from "../../hooks/destinations/useDestinations";
import { Select, SelectItem } from "@nextui-org/react";
import { useSessions } from "../../hooks/useSessions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@nextui-org/react";
import { parseDateTime } from "@internationalized/date";
import { now, getLocalTimeZone } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";
import moment from "moment";

export default function FormCrudTravels({
  readOnly,
  travel,
  onClose,
  travelAction,
}) {
  const {
    onSetForm,
    onSetFormValues,
    onCreateNewTravel,
    onUpdateTravel,
    loadingCreatingTravel,
    errorCreatingTravel,
    travelSchema,
    travels,
  } = useTravels(travelAction == "create" ? null : travel);

  const handlePress = () => {
    const travelsFiltered = travels.filter(
      (travel) => !travel.is_deleted && travel.id !== travelSchema.id 
    );
    const endDateTravelCreate = moment(travelSchema.end_date);
    const startDateTravelCreate = moment(travelSchema.start_date)
    const travelsSameDate = travelsFiltered.filter((travel) => {
      return (
        !(endDateTravelCreate.isBefore(travel.start_date) && startDateTravelCreate.isBefore(travel.end_date)) &&
        !(endDateTravelCreate.isAfter(travel.start_date) && startDateTravelCreate.isAfter(travel.end_date))
      );
    });
    const travelsSameDateDriver = travelsSameDate.filter(
      (travel) => travel.driver_id == travelSchema.driver_id
    );
    const travelsSameDateDriverVehicle = travelsSameDate.filter(
      (travel) => travel.vehicle_id == travelSchema.vehicle_id
    );
    if (
      travelsSameDateDriver.length > 0 &&
      travelsSameDateDriverVehicle.length > 0
    ) {
      setErrorMessage("Ya existe un viaje asignado al conductor y vehiculo");
    } else if (travelsSameDateDriver.length > 0) {
      setErrorMessage("Ya existe un viaje asignado al conductor");
    } else if (travelsSameDateDriverVehicle.length > 0) {
      setErrorMessage("Ya existe un viaje asignado al vehiculo");
    } else if (
      !(
        travelSchema.start_date == "" ||
        travelSchema.destination_id == "" ||
        travelSchema.end_date == "" ||
        travelSchema.driver_id == "" ||
        travelSchema.vehicle_id == ""
      )
    ) {
      setErrorMessage("");
      travelAction === "edit"
        ? onUpdateTravel(onClose)
        : onCreateNewTravel(onClose);
    }
  };

  const { transfers } = useTransfers();
  const { drivers } = useDrivers();
  const { destinations } = useDestinations();
  const { userInformation } = useSessions();
  const [valueStart, onChangeStart] = React.useState(
    travelSchema.start_date.length > 0
      ? parseDateTime(travelSchema.start_date)
      : now(getLocalTimeZone())
  );
  const [valueEnd, onChangeEnd] = React.useState(
    travelSchema.end_date.length > 0
      ? parseDateTime(travelSchema.end_date)
      : now(getLocalTimeZone())
  );
  const [errorSave, setErrorMessage] = React.useState("");

  const changeDateStart = (type, value) => {
    const dateValue = value.toDate();
    onSetForm(type, dateValue);
  };
  const changeDateEnd = (type, value) => {
    const dateValue = value.toDate();
    onSetForm(type, dateValue);
  };

  const changeTransfer = (value) => {
    const transferSelected = transfers.find((transfer) => transfer.id == value);
    onSetFormValues({
      vehicle_id: value,
      available_seats: transferSelected.capacity,
    });
  };

  useEffect(() => {
    onSetForm("created_by", userInformation.id);
  }, []);

  const errorMessage = errorCreatingTravel ? (
    <div className="text-[#f31260]">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        style={{ color: "#f31260" }}
      />
      <span className="ml-1">Error al realizar la operacion</span>
    </div>
  ) : null;

  return (
    <div id="root">
      <span>{errorSave}</span>
      <span>{errorMessage}</span>
      <div className="flex justify-between space-x-4">
        <Select
          label="Conductor"
          isDisabled={readOnly}
          className="max-w-xs"
          isRequired={true}
          value={travelSchema.driver_id}
          onChange={(event) => onSetForm("driver_id", event.target.value)}
          defaultSelectedKeys={[travelSchema.driver_id.toString()]}
        >
          {drivers.map((driver) => (
            <SelectItem key={driver.id} value={driver.id}>
              {driver.firstname}
            </SelectItem>
          ))}
        </Select>
      </div>

      <div className="flex justify-between space-x-4">
        <Select
          label="Transfer"
          className="max-w-xs space-y-4"
          isDisabled={readOnly}
          isRequired={true}
          onChange={(event) => changeTransfer(event.target.value)}
          defaultSelectedKeys={[travelSchema.vehicle_id.toString()]}
        >
          {transfers.map(
            ({ id, license_plate, brand, model, year, capacity }) => (
              <SelectItem key={id} value={id}>
                {`${license_plate} - ${brand} ${model} ${year} (${capacity})`}
              </SelectItem>
            )
          )}
        </Select>
      </div>

      <div className="flex justify-between ">
        <Select
          label="Destino"
          className="flex max-w-xs space-y-4"
          isDisabled={readOnly}
          isRequired={true}
          onChange={(event) => onSetForm("destination_id", event.target.value)}
          defaultSelectedKeys={[travelSchema.destination_id.toString()]}
        >
          {destinations.map((destination) => (
            <SelectItem key={destination.id} value={destination.id} sel>
              {destination.name}
            </SelectItem>
          ))}
        </Select>
      </div>
      <div className="mt-3">
        <div>
          <label>Fecha de salida: </label>
          <I18nProvider locale="es-ES">
            <DatePicker
              className="max-w-xs"
              value={valueStart}
              calendarWidth={400}
              hideTimeZone
              onChange={(value) => {
                onChangeStart(value);
                changeDateStart("start_date", value);
              }}
              defaultValue={now(getLocalTimeZone())}
            />
          </I18nProvider>
        </div>
        <div className="mt-3">
          <label>Fecha de llegada: </label>
          <I18nProvider locale="es-ES">
            <DatePicker
              className="max-w-xs"
              required={true}
              disabled={readOnly}
              onChange={(value) => {
                onChangeEnd(value);
                changeDateEnd("end_date", value);
              }}
              value={valueEnd}
              hideTimeZone
              defaultValue={now(getLocalTimeZone())}
            />
          </I18nProvider>
        </div>
      </div>

      <div className="flex flex-col gap-1 w-full">
        <div className="travels__save-button">
          <Button color="danger" variant="light" onPress={onClose}>
            Cerrar
          </Button>
          {!readOnly && (
            <Button
              color="primary"
              onPress={handlePress}
              isLoading={loadingCreatingTravel}
              isDisabled={loadingCreatingTravel}
            >
              Guardar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
