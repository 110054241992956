import React from "react";
import {
  Skeleton,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
} from "@nextui-org/react";

const CRUDSkeleton = () => {
  const skeletonRow = (
    <TableRow>
      <TableCell>
        <div className="max-w-[300px] w-full flex items-center gap-3">
          <div>
            <Skeleton className="flex rounded-full w-12 h-12" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="h-3 w-4/5 rounded-lg" />
            <Skeleton className="h-3 w-3/5 rounded-lg" />
          </div>
        </div>
      </TableCell>
      <TableCell>
        <Skeleton className="h-3 w-4/5 rounded-lg" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-3 w-2/5 rounded-lg" />
      </TableCell>
    </TableRow>
  );

  const skeletonRows = [skeletonRow, skeletonRow, skeletonRow, skeletonRow];

  return (
    <>
      <div className="flex justify-between m-5">
        <Skeleton className="rounded-lg w-3/6 h-10"/>
        <Skeleton className="rounded-lg w-1/6 h-10"/>
      </div>
      <Table>
        <TableHeader>
          <TableColumn>
            <Skeleton className="h-3 w-4/5 rounded-lg" />
          </TableColumn>
          <TableColumn>
            <Skeleton className="h-3 w-3/5 rounded-lg" />
          </TableColumn>
          <TableColumn>
            <Skeleton className="h-3 w-2/5 rounded-lg" />
          </TableColumn>
        </TableHeader>
        <TableBody>{skeletonRows}</TableBody>
      </Table>
    </>
  );
};

export default CRUDSkeleton;
