import React from "react";
import { Input, Button } from "@nextui-org/react";
import { PlusIcon } from "../commons/icons/PlusIcon";
import { SearchIcon } from "../commons/icons/SearchIcon";

export default function TopContentTableUsers({
  editOrCreateUser,
  onClear,
  onSearchChange,
  filterValue,
}) {
  return (
    <div className="users__buttons-principal">
      <div className="flex justify-between gap-3 items-end">
        <Input
          isClearable
          className="w-full sm:max-w-[44%]"
          placeholder="Buscar por nombre..."
          startContent={<SearchIcon />}
          value={filterValue}
          onClear={() => onClear()}
          onValueChange={onSearchChange}
        />
        <div className="flex gap-3">
          <Button
            color="primary"
            onPress={() =>
              editOrCreateUser("create", { id: "", name: "", email: "" })
            }
            endContent={<PlusIcon />}
          >
            Agregar Usuario
          </Button>
        </div>
      </div>
    </div>
  );
}
