// Vendors
import React from 'react';

export const CalendarEvent = ({eventInfo, onOpenModal}) => {
  return (
    <div className="calendar-event__container" onClick={() => onOpenModal(eventInfo)}>
      <div className="calendar-event__event-name capitalize">
        {eventInfo.event._def.extendedProps.destinationInfo.name}
      </div>
    </div>
  );
}
