import React from "react";
import { Input, Button } from "@nextui-org/react";
import useDrivers from "../../hooks/drivers/useDrivers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// Utilities
import { areInputsInvalid } from "../../utilities/formValidatorHelpper";


export default function FormCrudDrivers({ readOnly, driver, onClose, driverAction }) {

  const {
    onSetForm,
    onCreateNewDriver,
    onUpdateDriver,
    loadingCreatingDriver,
    errorCreatingDriver,
    driverSchema,
  } = useDrivers(driver);

  const errorMessage = errorCreatingDriver ? (
    <div className="text-[#f31260]">
      <FontAwesomeIcon icon={faTriangleExclamation} style={{color: "#f31260",}} />
      <span className="ml-1">
        Error al realizar la operacion
      </span>
    </div>
  ) : null;

  const handlePress = () => {
    driverAction === 'edit' ? onUpdateDriver(onClose) : onCreateNewDriver(onClose);
  };

  return (
    
    <div id="root">
      <div>{errorMessage}</div>
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={driverSchema.firstname}
        onChange={(event) => onSetForm( 'firstname', event.target.value )}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Nombre"
      />
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={driverSchema.lastname}
        onChange={(event) => onSetForm( 'lastname', event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Apellido"
      />
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={driverSchema.email}
        onChange={(event) => onSetForm( 'email', event.target.value )}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="E-mail"
      />

      <div className="flex flex-col gap-1 w-full">
        <div className="users__save-button">
          <Button color="danger" variant="light" onPress={onClose}>
            Cerrar
          </Button>
          {!readOnly && 
            <Button 
              color="primary"
              isLoading={loadingCreatingDriver}
              isDisabled={loadingCreatingDriver}
              onPress={handlePress}>
              Guardar
            </Button>
          }
        </div>
      </div>
    </div>
  );
}