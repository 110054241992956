import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableReservations from "./TopContentTableReservations";
import BottomContentTableReservations from "./BottomContentTableReservations";
import { STATUS_COLOR_MAP, COLUMNS_RESERVATION, INITIAL_VISIBLE_COLUMNS_RESERVATIONS} from "../../constants/index"
import CellReservations from "./CellReservations";
import useDrivers from "../../hooks/drivers/useDrivers";
import useTransfers from "../../hooks/transfers/useTransfers";
import useReservations from "../../hooks/reservations/useReservations";
import useDestinations from "../../hooks/destinations/useDestinations";
import useTravels from "../../hooks/travels/useTravels";
import usePayments from "../../hooks/payments/usePayments";



export default function TableReservations({
  setReservationSelected,
  setValueReservationAction,
  setValuePaymentAction,
  onOpen,
  onOpenDelete,
  trip,
  onOpenPayment,
  onOpenConfirm
}) {
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS_RESERVATIONS)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });
  const { drivers } = useDrivers();
  const { reservations } = useReservations();
  const { travels } = useTravels()
  const { transfers } = useTransfers();
  const { destinations } = useDestinations();
  const arrayReservation = reservations.filter(reservation => reservation.trip == trip).map(reservation => {
    return {
      id: reservation.id,
      created_at: reservation.created_at,
      created_by: reservation.created_by,
      seats_reserved: reservation.seats_reserved,
      client_name: reservation.client_name,
      price: reservation.price,
      passenger_list: reservation.passenger_list,
      confirmed: reservation.confirmed,
      trip: reservation.trip,
      payed: reservation.payed,
      left_to_pay: reservation.left_to_pay,
      client_type: reservation.client_type,
      destinationName: destinations.find( destination => destination.id == travels.find(travel => travel.id == reservation.trip).destination_id).name,
    }
  });
  const [page, setPage] = React.useState(1);
  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_RESERVATION;

    return COLUMNS_RESERVATION.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredReservations = [...arrayReservation];
    if (hasSearchFilter) {
      filteredReservations = filteredReservations.filter((reservation) =>
        reservation.client_name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_COLOR_MAP.length
    ) {
      filteredReservations = filteredReservations.filter((reservation) =>
        Array.from(statusFilter).includes(reservation.status)
      );
    }

    return filteredReservations;
  }, [arrayReservation, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateReservation = React.useCallback((reservationActionFlag, reservation) => {
    setValueReservationAction(reservationActionFlag);
    setReservationSelected(reservation);
    onOpen();
  });

  const deleteReservation = React.useCallback((reservation) => {
    setReservationSelected(reservation);
    onOpenDelete();
  });

  const paymentReservation = (paymentActionFlag, reservation) => {
    setValuePaymentAction(paymentActionFlag)
    setReservationSelected(reservation);
    onOpenPayment();
  };

  const confirmReservation = (reservation) => {
    setReservationSelected(reservation);
    onOpenConfirm();
  };


  return (
    <>
    <div className="reservations__total">
        <TopContentTableReservations
          editOrCreateReservation={editOrCreateReservation}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
          trip={trip}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden reservations__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No reservations found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellReservations reservation={item} columnKey={columnKey} editOrCreateReservation={editOrCreateReservation} deleteReservation={deleteReservation} confirmReservation={confirmReservation}/>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 reservations__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_RESERVATION}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No reservations found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellReservations reservation={item}  columnKey={columnKey} editOrCreateReservation={editOrCreateReservation} deleteReservation={deleteReservation} paymentReservation={paymentReservation} confirmReservation={confirmReservation}/>
                  </TableCell>

                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableReservations
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
        </div>
    </>
  );
}
