import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@nextui-org/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import useReservations from "../../hooks/reservations/useReservations";

export default function ModalConfirmReservation({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  element,
  isLoading,
  isInvalid,
}) {
  const {
    onConfirmReservation
  } = useReservations();
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Confirmar {titileModel}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <FontAwesomeIcon icon={faCircleCheck} size="4x" style={{color: "#00913f"}}/>
              </div>
              <div className="text-center my-5">
                <span className="text-center">
                  ¿Esta seguro que desea confirmar la reserva?
                </span>
                <div className="flex justify-center items-center space-x-4 mt-5">
                  <Button
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isInvalid={isInvalid}
                    onPress={onClose}
                  >
                    No
                  </Button>
                  <Button
                    color="success"
                    onPress={() => onConfirmReservation(element, onClose)}
                    isDisabled={isLoading}
                    isInvalid={isInvalid}
                  >
                    Si, confirmar
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
