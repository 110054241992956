// Vendors
import moment from "moment";

// Constants
import { DATE_FORMAT, DATE_FORMAT_WITHOUT_TIME } from "../constants";

const formatDate = (date, format = DATE_FORMAT.es) => {
  return moment(date).format(format);
};

const formatDateWithoutTime = (date, format = DATE_FORMAT_WITHOUT_TIME.es) => {
  moment(date).format(format);
  return moment(date).format(format);
};

const formatDateToEnd = (date, format = DATE_FORMAT.es) => {
  return moment(date).set({ hour: 23, minute: 59, seconds: 59 }).format(format);
};

const getTodayDateFormated = (format = DATE_FORMAT.headerDate) => {
  return moment().format(format);
};

export { formatDate, formatDateToEnd, getTodayDateFormated, formatDateWithoutTime };
