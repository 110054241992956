import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableTravels from "./TopContentTableTravels";
import BottomContentTableTravels from "./BottomContentTableTravels";
import {INITIAL_VISIBLE_COLUMNS_TRAVELS, STATUS_COLOR_MAP, COLUMNS_TRAVELS} from "../../constants/index"
import CellTravels from "./CellTravels";
import useTravels from "../../hooks/travels/useTravels";



export default function TableTravels({
  setTravelSelected,
  setValueTravelAction,
  onOpen,
  onOpenDelete
}) {
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS_TRAVELS)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });
  const {
    travels
  } = useTravels();
  const arrayTravel = travels.map(travel => {
    return {
      id: travel.id,
      destination: travel.destination.name,
      start_date: travel.start_date,
      end_date: travel.end_date,
      driver: travel.driver,
      transfer: travel.vehicle,
      status: travel.status,
    }
  });
  const [page, setPage] = React.useState(1);
  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_TRAVELS;

    return COLUMNS_TRAVELS.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredTravels = [...arrayTravel];

    if (hasSearchFilter) {
      filteredTravels = filteredTravels.filter((travel) =>
        travel.destination.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_COLOR_MAP.length
    ) {
      filteredTravels = filteredTravels.filter((travel) =>
        Array.from(statusFilter).includes(travel.status)
      );
    }

    return filteredTravels;
  }, [arrayTravel, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateTravel = React.useCallback((travelActionFlag, travel) => {
    setValueTravelAction(travelActionFlag);
    if (travel) {
      setTravelSelected(travel);
    }
    onOpen();
  });

  const deleteTravel = React.useCallback((travel) => {
    setTravelSelected(travel);
    onOpenDelete();
  });

  return (
    <>
    <div className="travels__total">
        <TopContentTableTravels
          editOrCreateTravel={editOrCreateTravel}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden travels__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No travels found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellTravels travel={item} columnKey={columnKey} editOrCreateTravel={editOrCreateTravel} deleteTravel={deleteTravel}/>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 travels__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_TRAVELS}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No travels found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellTravels travel={item} columnKey={columnKey} editOrCreateTravel={editOrCreateTravel} deleteTravel={deleteTravel}/>
                  </TableCell>

                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableTravels
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
        </div>
    </>
  );
}
