import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";
import {STATUS_COLOR_MAP, ADMIN_ROLE} from "../../constants/index"
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";

  export default function CellDrivers({
    driver,
    columnKey,
    editOrCreateDriver,
    deleteDriver,
    userRole,
  }) {
    const cellValue = driver[columnKey];
    switch (columnKey) {
      case "name":
        return (
          <User
            avatarProps={{ radius: "lg", src: driver.avatar }}
            description={driver.role}
            name={cellValue}
          >
            {driver.role}
          </User>
        );
      case "role":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "status":
        return (
          <Chip
            className="capitalize"
            color={STATUS_COLOR_MAP[driver.status]}
            size="sm"
            variant="flat"
          >
            {cellValue}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  onPress={() => editOrCreateDriver("view", driver)}
                >
                  Ver
                </DropdownItem>
                {userRole === ADMIN_ROLE && (
                  <DropdownItem
                    onPress={() => editOrCreateDriver("edit", driver)}
                  >
                    Editar
                  </DropdownItem>
                )}
                {userRole === ADMIN_ROLE && (
                  <DropdownItem onPress={() => deleteDriver(driver)}>
                    Eliminar
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }
  