// Vendors
import React, {useEffect} from "react";

//Components
import ModalTransferData from "../components/transfers/ModalTransferData";
import TableTransfers from "../components/transfers/TableTransfers";
import ModalDelete from "../components/commons/ModalDelete"
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import CRUDRetryComponent from "../components/commons/CRUDRetryComponent";
import { Toaster } from "react-hot-toast";
import RoleViewHandler from "../components/commons/RoleViewHandler";

//hooks
import useTransfers from "../hooks/transfers/useTransfers";
import { useDisclosure } from "@nextui-org/react";

//Constants
import { ADMIN_ROLE, TRANSFER_ROLE } from '../constants';

export default function Transfers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen , onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

  const {
    loadingTransfer,
    errorTransfer,
    fetchTransfer,
    loadingDeletingTransfer,
    errorDeletingTransfer,
    onDeleteTransfer,
  } = useTransfers();

  useEffect(() => {
    fetchTransfer();
  }, [])
  
  const [transferAction, setValueTransferAction] = React.useState("");
  let titileModel =
    transferAction === "create"
      ? "Crear transfer"
      : transferAction === "edit"
      ? "Editar transfer"
      : "Transfer";
  let fieldFlagReadOnly = true;
  let onlyDescription = false;
  if (transferAction === "create" || transferAction === "edit") {
    fieldFlagReadOnly = false;
  }
  

  const [transferSelected, setTransferSelected] = React.useState({
    id: "",
    licensePlate: "",
    brand:"",
    model:"",
    year:"",
    capacity: "",
    status: "",
    observations:"",
    suitableForDisabledPeople:""
  });

  if (loadingTransfer) {
    return <CRUDSkeleton/>;
  }

  if (errorTransfer) {
    return <CRUDRetryComponent type={'Transfers'} onRetryClick={fetchTransfer}/>;
  }

  return (
    <RoleViewHandler roleList={[ADMIN_ROLE, TRANSFER_ROLE]}>
      <Toaster position="top-center" />
      <ModalDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titileModel={titileModel}
        element={transferSelected.id}
        onDelete={onDeleteTransfer}
        isLoading={loadingDeletingTransfer}
        isInvalid={errorDeletingTransfer}
      />

      <ModalTransferData
        isOpen={isOpen}
        onClose={onClose}
        titileModel={titileModel}
        fieldFlagReadOnly={fieldFlagReadOnly}
        transferSelected={transferSelected}
        transferAction={transferAction}
        onlyDescription={onlyDescription}
      />
      <TableTransfers
        setTransferSelected={setTransferSelected}
        setValueTransferAction={setValueTransferAction}
        onOpenTransfer={onOpen}
        onOpenDelete={onDeleteOpen}
      />
    </RoleViewHandler>
  );
}
