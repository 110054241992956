import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableDrivers from "./TopContentTableDrivers";
import BottomContentTableDrivers from "./BottomContentTableDrivers";
import {INITIAL_VISIBLE_COLUMNS_DRIVER, COLUMNS_DRIVERS, STATUS_OPTIONS_DRIVERS} from "../../constants/index"
import CellDrivers from "./CellDrivers";
import useDrivers from "../../hooks/drivers/useDrivers";
import { useSessions } from '../../hooks/useSessions'

export default function TableDrivers({
  setDriverSelected,
  setValueDriverAction,
  onOpen,
  onDeleteOpen
}) {
  const {
    drivers,
  } = useDrivers();
  const {
    publicUserData: { userRole },
  } = useSessions();
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS_DRIVER)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_DRIVERS;

    return COLUMNS_DRIVERS.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredDrivers = [...drivers];

    if (hasSearchFilter) {
      filteredDrivers = filteredDrivers.filter((driver) =>
        driver.firstname.toLowerCase().includes(filterValue)
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_OPTIONS_DRIVERS.length
    ) {
      filteredDrivers = filteredDrivers.filter((driver) =>
        Array.from(statusFilter).includes(driver.status)
      );
    }

    return filteredDrivers;
  }, [drivers, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateDriver = React.useCallback((driverActionFlag, driver) => {
    setValueDriverAction(driverActionFlag);
    setDriverSelected(driver);
    onOpen();
  });

  const deleteDriver = React.useCallback((user) => {
    setDriverSelected(user);
    onDeleteOpen();
  });

  return (
    <>
      <div className="users__total">
        <TopContentTableDrivers
          editOrCreateDriver={editOrCreateDriver}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden users__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No drivers found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellDrivers
                      userRole={userRole}
                      driver={item}
                      columnKey={columnKey}
                      editOrCreateDriver={editOrCreateDriver}
                      deleteDriver={deleteDriver}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 users__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_DRIVERS}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No drivers found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellDrivers
                      userRole={userRole}
                      driver={item}
                      columnKey={columnKey}
                      editOrCreateDriver={editOrCreateDriver}
                      deleteDriver={deleteDriver}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableDrivers
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </div>
    </>
  );
}
