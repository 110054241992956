// Vendors
import React from 'react'

// Utilities
import {getTodayDateFormated} from '../../utilities/dateHelpper'

// Components
import { Button } from '@nextui-org/react'
import { CalendarHeaderDropdown } from './CalendarHeaderDropdown';

// Custom Hooks
import { useCalendarHeader } from '../../hooks/calendar/useCalendarHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowLeft, faArrowRight } from '@fortawesome/fontawesome-free-solid'


export const CalendarHeader = ({calendarRef}) => {

  const {handleCalendarView , handleDateChange } = useCalendarHeader(calendarRef);

  if (!calendarRef.current) {
    calendarRef.current = document.querySelector('#calendar');
  }
  let headerDate; 
  if (calendarRef.current) {
    headerDate = calendarRef.current.calendar.currentData.viewTitle;
  } else {
    headerDate =  getTodayDateFormated();
  }

  return (
    <div className="calendar-header__row">
      <div className="calendar-header__container w-full max-w-[1024px]">
        <div className="calendar-header__left-panel flex">
        <div className='calendar-header__title capitalize'>
          {headerDate}
        </div>
          <Button
            className="ml-2.5 border-none "
            size="sm"
            radius="none"
            variant="bordered"
            isIconOnly
            color="primary"
            onClick={() => handleDateChange('prev')}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
          </Button>
          <Button
            className="mx-1 border-none "
            size="sm"
            radius="none"
            variant="bordered"
            isIconOnly
            color="primary"
            onClick={() => handleDateChange('next')}
          >
            <FontAwesomeIcon icon={faArrowRight} size="xl" />
          </Button>
        </div>
        <div className="calendar-header__right-panel flex">
          <Button
            size="sm"
            color="primary"
            variant="flat"
            onClick={() => handleDateChange("today")}
          >
            <strong>Hoy</strong>
          </Button>
          <CalendarHeaderDropdown
            handleCalendarView={handleCalendarView}
          />
        </div>
      </div>
    </div>
  );
}
