import React,  { useEffect } from "react";
import { useDisclosure } from "@nextui-org/react";

//Components
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import CRUDRetryComponent from "../components/commons/CRUDRetryComponent";
import ModalDriverData from "../components/drivers/ModalDriverData";
import TableDrivers from "../components/drivers/TableDrivers";
import { Toaster } from "react-hot-toast";
import RoleViewHandler from "../components/commons/RoleViewHandler";


//Hooks
import useDrivers from "../hooks/drivers/useDrivers";
import ModalDelete from "../components/commons/ModalDelete"

//Constants
import { ADMIN_ROLE, TRANSFER_ROLE } from '../constants';


export default function Drivers() {
  const {
    fetchDriver,
    loadingDriver,
    errorDriver,
    onDeleteDriver,
    loadingDeletingDriver,
    errorDeletingDriver
  } = useDrivers();

  useEffect(() => {
    fetchDriver();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen , onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const [driverAction, setValueDriverAction] = React.useState("");

  const titileModel =
    driverAction === "create"
      ? "Crear conductor"
      : driverAction === "edit"
      ? "Editar conductor"
      : "Conductor";
  let fieldFlagReadOnly = true;
  if (driverAction === "create" || driverAction === "edit") {
    fieldFlagReadOnly = false;
  }

  const [driverSelected, setDriverSelected] = React.useState({
    id:'',
    firstname:'',
    lastname:'',
    email: ''
  });

  if (loadingDriver) {
    return <CRUDSkeleton />
  }

  if (errorDriver) {
    return <CRUDRetryComponent type={'driver'} onRetryClick={fetchDriver}/>;
  }

  return (
    <RoleViewHandler roleList={[ADMIN_ROLE, TRANSFER_ROLE]}>
      <Toaster position="top-center" />
      <ModalDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onDelete={onDeleteDriver}
        element={driverSelected.id}
        titileModel={titileModel}
        isLoading={loadingDeletingDriver}
        isInvalid={errorDeletingDriver}
      />
      <ModalDriverData
        isOpen={isOpen}
        onClose={onClose}
        titileModel={titileModel}
        fieldFlagReadOnly={fieldFlagReadOnly}
        driverSelected={driverSelected}
        driverAction={driverAction}
      />
      <TableDrivers
        setDriverSelected={setDriverSelected}
        setValueDriverAction={setValueDriverAction}
        onOpen={onOpen}
        onDeleteOpen={onDeleteOpen}
      />
    </RoleViewHandler>
  );
}
