import React from "react";
import "leaflet/dist/leaflet.css";
import { useEffect } from "react";

import {
  Input,
  Button,
  Textarea,
  Spinner,
  Card,
  CardBody,
} from "@nextui-org/react";
import useDestinations from "../../hooks/destinations/useDestinations";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  useMapEvents,
} from "react-leaflet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

export default function FormMapPosition({
  readOnly,
  destination,
  onClose,
  destinationAction,
  onSetForm,
}) {
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [16, 37],
  });

  const [center, setCenter] = React.useState(
    destination.latitud && destination.longitud
      ? { lng: destination.longitud, lat: destination.latitud }
      : [-32.888374, -68.875342]
  );

  useEffect(() => {
    setCenter([destination.latitud, destination.longitud]);
  }, [destination]);

  L.Marker.prototype.options.icon = DefaultIcon;

  const LocationMarker = () => {
    const [position, setPosition] = React.useState(
      destination.latitud && destination.longitud
        ? { lng: destination.longitud, lat: destination.latitud }
        : null
    );
    const map = useMapEvents({
      click(e) {
        if (!readOnly) {
          map.locate();
          destination.latitud = Number(e.latlng.lat.toFixed(2));
          destination.longitud = Number(e.latlng.lng.toFixed(2));
          setPosition(e.latlng);
        }
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>{destination.name}</Popup>
      </Marker>
    );
  };

  return (
    <div>
      <MapContainer
        center={center}
        zoom={13}
        scrollWheelZoom={true}
        style={{ height: "70vh", width: "100wh" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
    <div className="flex justify-end">
      <Button
        color="primary"
        onPress={onClose}
      >
        OK
      </Button>
      </div>
    </div>
  );
}
