import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";

import { getUserItemRole } from "../../utilities/rolePermissionHelpper"

  export default function CellUsers({user, columnKey, editOrCreateUser, deleteUser, onSendMail}) {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "firstname":
        return (
          <User
            avatarProps={{ radius: "lg", src: "https://www.usc.gal/export9/sites/webinstitucional/litlinal/.galleries/imaxes/fotoGenerica.png" }}
            description={getUserItemRole(user)}
            name={`${user.firstname} ${user.lastname}`}
          >
            {user.role}
          </User>
        );
      case "role":
        return (

          <div className="flex flex-col">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "is_deleted":
        return (
          <Chip
            className="capitalize"
            color={cellValue ? "danger" : "success"}
            size="sm"
            variant="flat"
          >
            {cellValue ? "Desactivado" : "Activo" }
          </Chip>
        );
      case "has_updated_initial_password":
        return (
          <Chip
            className="capitalize"
            color={cellValue ? "success" : "warning"}
            size="sm"
            variant="flat"
          >
            {cellValue ? "Confirmado" : "No Confirmado"}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onPress={() => editOrCreateUser("view", user)}>
                  Ver
                </DropdownItem>
                {user.is_deleted ? null : (
                  <DropdownItem onPress={() => editOrCreateUser("edit", user)}>
                    Editar
                  </DropdownItem>
                )}
                {user.is_deleted ? null : (
                  <DropdownItem onPress={() => deleteUser(user)}>
                    Eliminar
                  </DropdownItem>
                )}
                {user.is_deleted || user.has_updated_initial_password ? null : (
                  <DropdownItem onPress={() => onSendMail(user)}>
                    Reenviar Mail de confirmacion
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return  cellValue;
    }
  }
  