// Vendors
import React from "react";

// Components
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableTransfers from "./TopContentTableTransfers";
import BottomContentTableTransfers from "./BottomContentTableTransfers";
import CellTransfers from "./CellTransfers";

// Constants
import {INITIAL_VISIBLE_COLUMNS_TRANSFERS, STATUS_COLOR_MAP, COLUMNS_TRANSFERS} from "../../constants/index"

// Hooks
import useTransfers from "../../hooks/transfers/useTransfers";
import { useSessions } from '../../hooks/useSessions'

export default function TableTransfers({
  setTransferSelected,
  setValueTransferAction,
  onOpenTransfer,
  onOpenDelete
}) {
  const { transfers } = useTransfers();
  const {
    publicUserData: { userRole },
  } = useSessions();
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS_TRANSFERS)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_TRANSFERS;

    return COLUMNS_TRANSFERS.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredTransfers = [...transfers];

    if (hasSearchFilter) {
      filteredTransfers = filteredTransfers.filter((transfer) =>
        transfer.license_plate.toLowerCase().includes(filterValue.toLowerCase()) ||
        transfer.brand.toLowerCase().includes(filterValue.toLowerCase()) ||
        transfer.model.toLowerCase().includes(filterValue.toLowerCase()) 
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_COLOR_MAP.length
    ) {
      filteredTransfers = filteredTransfers.filter((transfer) =>
        Array.from(statusFilter).includes(transfer.status)
      );
    }

    return filteredTransfers;
  }, [transfers, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateTransfer = React.useCallback((transferActionFlag, transfer) => {
    setValueTransferAction(transferActionFlag);
    setTransferSelected(transfer);
    onOpenTransfer();
  });

  const deleteTransfer = React.useCallback((transfer) => {
    setTransferSelected(transfer);
    onOpenDelete();
  });

  return (
    <>
      <div className="transfers__total">
        <TopContentTableTransfers
          editOrCreateTransfer={editOrCreateTransfer}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden transfers__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No transfers found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellTransfers
                      userRole={userRole}
                      transfer={item}
                      columnKey={columnKey}
                      editOrCreateTransfer={editOrCreateTransfer}
                      deleteTransfer={deleteTransfer}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 transfers__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_TRANSFERS}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No transfers found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellTransfers
                      userRole={userRole}
                      transfer={item}
                      columnKey={columnKey}
                      editOrCreateTransfer={editOrCreateTransfer}
                      deleteTransfer={deleteTransfer}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableTransfers
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </div>
    </>
  );
}
