import { useState } from "react";

import {
  getEvents,
  updateEventStatus
} from "../../store/actions/eventActions";

import { useDispatch, useSelector } from "react-redux";

const useEvents = () => {
  const dispatch = useDispatch();
  const fetchEvents = () => dispatch(getEvents());
  const onUpdateEventStatus = (eventId, newStatus, successCallback) =>
    dispatch(updateEventStatus(eventId, newStatus, successCallback));

  const eventState = useSelector((state) => state.events);
  const {
    events,
    loadingEvent,
    errorEvent,
    loadingUpdatingEventStatus,
    errorUpdatingEventStatus,
  } = eventState;


  return {
    fetchEvents,
    loadingEvent,
    errorEvent,
    events,
    onUpdateEventStatus,
    loadingUpdatingEventStatus,
    errorUpdatingEventStatus,
  }
}

export default useEvents;