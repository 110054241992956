const areInputsInvalid = (inputsArr, formData) => {
  return inputsArr.some(
    (input) =>
      formData[input] === null ||
      formData[input] === undefined ||
      formData[input] === ""
  );
};

export { areInputsInvalid };
