// Vendors
import React, { useEffect } from "react";

// Components
import ModalReservationsData from "../components/reservations/ModalReservationsData";
import TableReservations from "../components/reservations/TableReservations";
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import ModalDelete from "../components/commons/ModalDelete";
import RoleViewHandler from "../components/commons/RoleViewHandler";
import ModalAddPayments from "../components/payments/ModalAddPayments";

// Hooks
import { useDisclosure } from "@nextui-org/react";
import useDestinations from "../hooks/destinations/useDestinations";
import useDrivers from "../hooks/drivers/useDrivers";
import useTransfers from "../hooks/transfers/useTransfers";
import useTravels from "../hooks/travels/useTravels";

import { useParams } from 'react-router-dom';


import useReservations from "../hooks/reservations/useReservations";

//Constants
import { ADMIN_ROLE, TRANSFER_ROLE } from "../constants";
import ModalConfirmReservation from "../components/commons/ModalConfirmReservation";

export default function Reservations() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reservationAction, setValueReservationAction] = React.useState("");
  const [paymentAction, setValuePaymentAction] = React.useState("");
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isAddPaymentOpen,
    onOpen: onPaymentOpen,
    onClose: onPaymentClose,
  } = useDisclosure();
  const {
    destinations,
    fetchDestination,
    loadingDestination,
    errorDestination,
  } = useDestinations();
  const { id } = useParams();
  const { drivers, fetchDriver, loadingDriver, errorDriver } = useDrivers();

  const {
    reservations,
    fetchReservation,
    loadingReservation,
    errorReservation,
    errorDeletingReservation,
    loadingDeletingReservation,
    onDeleteReservation,
    onConfirmReservation
  } = useReservations();

  const {
    travels,
    fetchTravel,
    loadingTravel,
    errorTravel,
    errorDeletingTravel,
    loadingDeletingTravel,
    onDeleteTravel,
  } = useTravels();
  
  const { transfers, fetchTransfer, loadingTransfer, errorTransfer } =
    useTransfers();

  useEffect(() => {
    fetchDestination();
    fetchDriver();
    fetchTransfer();
    fetchReservation();
    fetchTravel();
  }, []);

  const [reservationSelected, setReservationSelected] = React.useState({});
  if (loadingDestination || loadingDriver || loadingTransfer || loadingReservation) {
    return <CRUDSkeleton />;
  }
  let createPay =  true;
  let onlyPassengers = false;
  let showReservations = false;
  const titileModel =
    reservationAction === "create"
      ? "Crear reserva"
      : reservationAction == "addpassangers"
      ? "Agregar pasajeros"
      : reservationAction == "showreservations"
      ? "Reservas"
      : "";
  let fieldFlagReadOnly = true;
  if (reservationAction === "create" || reservationAction === "edit") {
    fieldFlagReadOnly = false;
  } else if (reservationAction == "addpassangers") {
    onlyPassengers = true;
  }else if (reservationAction == "showreservations"){
    onlyPassengers = true;
  }
  if(paymentAction == 'show'){
    createPay = false;
  }

  return (
    <RoleViewHandler roleList={[ADMIN_ROLE, TRANSFER_ROLE]}>
      <ModalDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titileModel={titileModel}
        element={reservationSelected}
        onDelete={onDeleteReservation}
        isLoading={loadingDeletingReservation}
        isInvalid={errorDeletingReservation}
      />
      
      <ModalReservationsData
        isOpen={isOpen}
        onClose={onClose}
        titileModel={titileModel}
        fieldFlagReadOnly={fieldFlagReadOnly}
        reservationSelected={reservationSelected}
        reservationAction={reservationAction}
        onlyPassengers={onlyPassengers}
        showReservations={showReservations}
        tripId={id}
      />

      <ModalAddPayments
        isCreate={createPay}
        isOpen={isAddPaymentOpen}
        onOpenChange={onPaymentOpen}
        onClose={onPaymentClose}
        reservationSelected={reservationSelected}
        setReservationSelected={setReservationSelected}
      ></ModalAddPayments>

      <ModalConfirmReservation
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        titileModel={titileModel}
        element={reservationSelected}
        isLoading={loadingDeletingReservation}
        isInvalid={errorDeletingReservation}
      >
      </ModalConfirmReservation>

      <TableReservations
        setReservationSelected={setReservationSelected}
        setValueReservationAction={setValueReservationAction}
        setValuePaymentAction={setValuePaymentAction}
        onOpen={onOpen}
        trip={id}
        onOpenDelete={onDeleteOpen}
        onOpenConfirm={onConfirmOpen}
        onOpenPayment={onPaymentOpen}
      />
    </RoleViewHandler>
  );
}
