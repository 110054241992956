// Vendors
import React from "react";

// Components
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";

// Constants
import { CALENDAR_VIEWS } from "../../constants";

export const CalendarHeaderDropdown = ({ handleCalendarView }) => {
  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["Mes"]));

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  const setNewView = (newView) => {
    setSelectedKeys(newView);
    handleCalendarView(CALENDAR_VIEWS[newView.anchorKey]);
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button variant="bordered" className="capitalize ml-2.5" size="sm">
          {selectedValue}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Single selection example"
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedKeys}
        onSelectionChange={setNewView}
      >
        <DropdownItem key="mes">Mes</DropdownItem>
        <DropdownItem key="semana">Semana</DropdownItem>
        <DropdownItem key="dia">Dia</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
