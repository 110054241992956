import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingEvent: false,
  errorEvent: false,
  events: [],
  loadingUpdatingEventStatus: false,
  errorUpdatingEventStatus: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EVENTS_FETCH_LOADING:
      return {
        ...state,
        loadingEvent: true,
        errorEvent: false,
      };
    case actionTypes.EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loadingEvent: false,
        errorEvent: false,
      };
    case actionTypes.EVENTS_FETCH_FAILURE:
      return {
        ...state,
        loadingEvent: false,
        errorEvent: true,
      };
    case actionTypes.EVENT_STATUS_UPDATE_LOADING:
      return {
        ...state,
        loadingUpdatingEventStatus: true,
        errorUpdatingEventStatus: false,
      }
    case actionTypes.EVENT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdatingEventStatus: false,
        errorUpdatingEventStatus: false,
      }
    case actionTypes.EVENT_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loadingUpdatingEventStatus: false,
        errorUpdatingEventStatus: true,
      }
    default:
      return state;
  }
}

export default eventReducer;
