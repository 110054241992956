import { Outlet, Navigate  } from "react-router-dom";
import { useSessions } from "../hooks/useSessions";


const PrivateRoutes = () => {
  const { isLogged, publicUserData } = useSessions();
  if (isLogged && publicUserData) {
    const hasUpdatedInitialPassword =
      publicUserData.has_updated_initial_password;
    if (hasUpdatedInitialPassword) {
      return <Outlet />;
    } else {
      return <Navigate to="/reset-password" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
