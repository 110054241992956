import { createClient } from "@supabase/supabase-js";
import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";


const logInRequest = () => ({
  type: actionTypes.LOGIN_REQUEST_LOADING,
});

const logInSuccess = () => ({
  type: actionTypes.LOGIN_REQUEST_SUCCESS,
});

const logInFailure = () => ({
  type: actionTypes.LOGIN_REQUEST_FAILURE,
});

const logIn = (formData, navigate) => (dispatch) => {
  dispatch(logInRequest());
  supabase.auth
    .signInWithPassword({
      email: formData.email,
      password: formData.password,
    })
    .then(async (response) => {
      const { data, error } = response;
      if (!error && !!data && !!data.session) {
        await dispatch(logInSuccess());
        await dispatch(getSession(data.user.id));
        navigate('/')
      } else {
        dispatch(logInFailure());
      }
    })
    .catch(() => {
      dispatch(logInFailure());
    });
};

const logOutAction = () => ({
  type: actionTypes.LOGOUT_REQUEST,
});

const logOut = () => async (dispatch) => {
  const { error } = await supabase.auth.signOut();
  if (!error) {
    window.location.reload();
    dispatch(logOutAction());
  }
};

const getSession = (id = null) => (dispatch) => {
  dispatch({ type: actionTypes.SESSION_REQUEST_LOADING });
  supabase.auth
    .getSession()
    .then((response) => {
      const session = response.data;
      supabase.auth
        .getUser()
        .then((userDataResponse) => {
          const userData = userDataResponse.data;
          if (!!userData) {
            supabase
              .from("users")
              .select("*, user_roles(role)")
              .eq("id", id ? id : userData.user.id)
              .then((publicUserDataResponse) => {
                const publicUserData =
                  !!publicUserDataResponse.data &&
                  publicUserDataResponse.data.length > 0 &&
                  publicUserDataResponse.data[0]
                  if (publicUserData) {
                    dispatch({
                      type: actionTypes.SESSION_REQUEST_SUCCESS,
                      payload: {
                        session,
                        userData,
                        publicUserData,
                      },
                    });
                  } else {
                    dispatch({
                      type: actionTypes.SESSION_REQUEST_FAILURE,
                    });
                  }
              });
          }else {
            dispatch({
              type: actionTypes.SESSION_REQUEST_FAILURE,
            });
          }
        })
        .catch(() => {
          dispatch({
            type: actionTypes.SESSION_REQUEST_FAILURE,
          });
        });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SESSION_REQUEST_FAILURE,
      });
    });
};

const registerRequest = () => ({
  type: actionTypes.USER_REGISTER_REQUEST_LOADING,
});

const registerSuccess = () => ({
  type: actionTypes.USER_REGISTER_REQUEST_SUCCESS,
});

const registerFailure = () => ({
  type: actionTypes.USER_REGISTER_REQUEST_FAILURE,
});

const userRegister = (userForm, callback) => async (dispatch) => {
  dispatch(registerRequest());
  const { error } = await supabase.functions.invoke('users', {
    method: 'POST',
    body: {
      type: 'create',
      email: userForm.email, 
      role: userForm.role, 
      first_name: userForm.firstName, 
      last_name: userForm.lastName
    },
  });
  if (!error) {
    dispatch(registerSuccess());
    dispatch(getUsers());
    callback();
  } else {
    dispatch(registerFailure());
  }
};

const userDeleteRequest = () => ({
  type: actionTypes.USER_DELETE_REQUEST_LOADING,
});

const userDeleteSuccess = () => ({
  type: actionTypes.USER_DELETE_REQUEST_SUCCESS,
});

const userDeleteFailure = () => ({
  type: actionTypes.USER_DELETE_REQUEST_FAILURE,
});

const userDelete = (id, callback) => async (dispatch) => {
  dispatch(userDeleteRequest());
  const { error } = await supabase.functions.invoke('users', {
    method: 'DELETE',
    body: { id },
  });
  if (!error) {
    dispatch(userDeleteSuccess());
    dispatch(getUsers());
    callback();
  } else {
    dispatch(userDeleteFailure());
  }
};

const userUpdateRequest = () => ({
  type: actionTypes.USER_UPDATE_REQUEST_LOADING,
});

const userUpdateSuccess = () => ({
  type: actionTypes.USER_UPDATE_REQUEST_SUCCESS,
});

const userUpdateFailure = () => ({
  type: actionTypes.USER_UPDATE_REQUEST_FAILURE,
});

const userUpdate = (userForm, callback) => async (dispatch) => {
  dispatch(userUpdateRequest());
  const { error } = await supabase.functions.invoke('users', {
    method: 'PUT',
    body: {
      id: userForm.id,
      email: userForm.email, 
      role: userForm.role, 
      first_name: userForm.firstName, 
      last_name: userForm.lastName
    },
  });
  if (!error) {
    dispatch(userUpdateSuccess());
    dispatch(getUsers());
    callback();
  } else {
    dispatch(userUpdateFailure());
  }
};

const sendConfirmationMailRequestLoading = () => ({
  type: actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_LOADING,
});

const sendConfirmationMailRequestSuccess = () => ({
  type: actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_SUCCESS,
});

const sendConfirmationMailRequestFailure = () => ({
  type: actionTypes.SEND_CONFIRMATION_MAIL_REQUEST_FAILURE,
});

const sendConfirmationMail = (email, callback) => async (dispatch) => {
  dispatch(sendConfirmationMailRequestLoading());
  const { error } = await supabase.functions.invoke("users", {
    method: "POST",
    body: { type: "email", email },
  });
  if (!error) {
    dispatch(sendConfirmationMailRequestSuccess());
    callback();
  } else {
    dispatch(sendConfirmationMailRequestFailure());
  }
};

const forgotPasswordRequest = () => ({
  type: actionTypes.USER_FORGOT_PASSWORD_REQUEST_LOADING,
});

const forgotPasswordSuccess = () => ({
  type: actionTypes.USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
});

const forgotPasswordFailure = () => ({
  type: actionTypes.USER_FORGOT_PASSWORD_REQUEST_FAILURE,
});

const forgotPassword = email => async dispatch => {
  dispatch(forgotPasswordRequest());
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: '/reset-password',
  })
  if(!error) {
    await dispatch(logOut());
    dispatch(forgotPasswordSuccess());
  } else {
    dispatch(forgotPasswordFailure());
  }
}

const resetPasswordRequest = () => ({
  type: actionTypes.USER_RESET_PASSWORD_REQUEST_LOADING,
});

const resetPasswordSuccess = () => ({
  type: actionTypes.USER_RESET_PASSWORD_REQUEST_SUCCESS,
});

const resetPasswordFailure = () => ({
  type: actionTypes.USER_RESET_PASSWORD_REQUEST_FAILURE,
});

const resetPassword = (resetPasswordForm, navigate) => async (dispatch, getState) => {
  const {isLogged, publicUserData } = getState().session;
  if (
    isLogged &&
    resetPasswordForm &&
    resetPasswordForm.newPassword === resetPasswordForm.newPasswordRepeat
  ) {
    dispatch(resetPasswordRequest());
    const { error } = await supabase.auth.updateUser({
      password: resetPasswordForm.newPassword,
    });
    if (!error) {
      dispatch(resetPasswordSuccess());
      await supabase.from('users').update({ has_updated_initial_password: true })
      .eq('email', publicUserData.email)
      .select()
      await dispatch(logOut());
      navigate("/login");
    } else {
      dispatch(resetPasswordFailure());
    }
  } else {
    dispatch(resetPasswordFailure());
  }
};

const getUsersRequest = () => ({
  type: actionTypes.GET_USERS_REQUEST_LOADING,
});

const getUsersSuccess = (data) => ({
  type: actionTypes.GET_USERS_REQUEST_SUCCESS,
  payload: {
    data: data,
  }
});

const getUsersFailure = () => ({
  type: actionTypes.GET_USERS_REQUEST_FAILURE,
});

const getUsers = () => async (dispatch, getState) => {
  const { isLogged } = getState().session;
  dispatch(getUsersRequest());
  if (isLogged) {
    const { data, error } = await supabase
      .from("users")
      .select("*, user_roles(role)");
    if (!error) {
      dispatch(getUsersSuccess(data));
    } else {
      dispatch(getUsersFailure());
    }
  }
}

export {
  logIn,
  logOut,
  getSession,
  userRegister,
  forgotPassword,
  resetPassword,
  getUsers,
  userDelete,
  sendConfirmationMail,
  userUpdate,
};
