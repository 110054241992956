import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingDriver: false,
  errorDriver: false,
  drivers: [],
  loadingCreatingDriver: false,
  errorCreatingDriver: false,
  loadingDeletingDriver: false,
  errorDeletingDriver: false,
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DRIVERS_FETCH_LOADING:
      return {
        ...state,
        loadingDriver: true,
        errorDriver: false,
      };
    case actionTypes.DRIVERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingDriver: false,
        drivers: action.payload,
        errorDriver: false,
      };
    case actionTypes.DRIVERS_FETCH_FAILURE:
      return {
        ...state,
        loadingDriver: false,
        errorDriver: true,
      };
    case actionTypes.DRIVER_CREATE_LOADING:
      return {
        ...state,
        loadingCreatingDriver: true,
        errorCreatingDriver: false,
      };
    case actionTypes.DRIVER_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreatingDriver: false,
        errorCreatingDriver: false,
      };
    case actionTypes.DRIVER_CREATE_FAILURE:
      return {
        ...state,
        loadingCreatingDriver: false,
        errorCreatingDriver: true,
      };
    case actionTypes.DRIVER_DELETE_LOADING:
      return {
        ...state,
        loadingDeletingDriver: true,
        errorDeletingDriver: false,
      };
    case actionTypes.DRIVER_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletingDriver: false,
        errorDeletingDriver: false,
      };
    case actionTypes.DRIVER_DELETE_FAILURE:
      return {
        ...state,
        loadingDeletingDriver: false,
        errorDeletingDriver: true,
      };
    default:
      return state;
  }
};

export default driverReducer;
