import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import moment from "moment";
import * as FileSaver from "file-saver";



const sendReportRequest = () => ({
  type: actionTypes.REPORT_SEND_REQUEST_LOADING,
});

const sendReportSuccess = (response) => ({
  type: actionTypes.REPORT_SEND_REQUEST_SUCCESS,
  payload: response,
});

const sendReportFailure = () => ({
  type: actionTypes.REPORT_SEND_REQUEST_FAILURE,
});

const saveAsExcelFile = (buffer, fileName) => {
  let EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let EXCEL_EXTENSION = ".xlsx";
  const data = new Blob([buffer], {
    type: EXCEL_TYPE,
  });
  FileSaver.saveAs(
    data,
    fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  );
};
const translateAndTransform = (data, items) => {
  // Diccionario para la traducción de los atributos al español
  const translation = {
    destinations: "destinos",
    distance_traveled: "distancia_recorrida",
    driver_name: "nombre_conductor",
    passengers_count: "cantidad_pasajeros",
    travel_count: "cantidad_viajes",
    license_plate: "placa_vehiculo",
    destination_name: "nombre_destino",
    vehicles: "vehiculos",
    distance: "distancia",
    trip_date: "fecha_viaje",
    passengers_list: "lista_pasajeros",
    client_name: "nombre_cliente",
    client_type: "tipo_cliente",
    passenger_list: "lista_pasajeros",
    price: "precio",
    trip_date: "fecha_viaje"
  };
  // Función para traducir y transformar un solo objeto
  const translateAndTransform = (data) => {
    let translatedData = {};
    for (let key in translation) {
      if (data.hasOwnProperty(key)) {
        if (key === 'passengers_list' && Array.isArray(data[key])) {
          let passengersList = data[key]
            .filter(group => group !== null)
            .map(group =>
              group
                .filter(passenger => passenger !== null)
                .map(passenger => {
                  let name = passenger.namePassenger || "";
                  let surname = passenger.surnamePassenger || "";
                  let dni = passenger.dniPassenger || "";
                  return `${name} ${surname} ${dni}`.trim();
                })
                .filter(item => item !== "").join(", ")
            )
            .filter(item => item !== "").join(", ");
          translatedData[translation[key]] = passengersList;
        } else if (Array.isArray(data[key])) {
          translatedData[translation[key]] = data[key].filter(item => item !== null).join(", ");
        } else {
          translatedData[translation[key]] = data[key];
        }
      }
    }
    return translatedData;
  }

  // Procesar cada objeto en el array
  return data.map(d => translateAndTransform(d));
}

const exportExcel = async (data, typeReport, items) => {
  data = translateAndTransform(data, items);
  const worksheet = await import("xlsx").then((xlsx) =>
    xlsx.utils.json_to_sheet(data)
  );
  const workbook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };
  const excelBuffer = await import("xlsx").then((xlsx) =>
    xlsx.write(workbook, { bookType: "xlsx", type: "array" })
  );
  saveAsExcelFile(excelBuffer, typeReport);
};

const sendReport = (reportForm, items) => async (dispatch) => {
  dispatch(sendReportRequest());
  const {data, error} = await supabase.functions.invoke("reports", {
    method: "POST",
    body: {
      type: reportForm.report,
      metrics: reportForm.metrics,
      from: moment
        .tz(
          {
            year: reportForm.from.year,
            month: reportForm.from.month - 1,
            day: reportForm.from.day,
            hour: reportForm.from.hour,
            minute: reportForm.from.minute,
            second: reportForm.from.second,
            millisecond: reportForm.from.millisecond,
          },
          reportForm.from.timeZone
        )
        .toLocaleString("en-US"),
      to: moment
        .tz(
          {
            year: reportForm.to.year,
            month: reportForm.to.month - 1,
            day: reportForm.to.day,
            hour: reportForm.to.hour,
            minute: reportForm.to.minute,
            second: reportForm.to.second,
            millisecond: reportForm.to.millisecond,
          },
          reportForm.to.timeZone
        )
        .toLocaleString("en-US"),
    },
  });
  if (!error) {
    exportExcel(data, reportForm.report, items)
    dispatch(sendReportSuccess(data));
  } else {
    dispatch(sendReportFailure());
  }
}

export {
  sendReport
}