// Vendors
import React from "react";

// Components
import { HomeCalendar } from "../components/home/HomeCalendar";

export default function Home() {
  return (
    <div id="root">
      <HomeCalendar />
    </div>
  );
}
