import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from "@nextui-org/react";


import { faTrash } from '@fortawesome/fontawesome-free-solid'

export default function CellPassengers({passenger, columnKey, removePassenger}) {
  const cellValue = passenger[columnKey];
  switch (columnKey) {
    case "button-delete":
      return (
        <Button
          className="ml-2.5 border-none "
          size="sm"
          radius="none"
          variant="bordered"
          isIconOnly
          color="primary"
          onClick={()=>{removePassenger(passenger)}}
        >
          <FontAwesomeIcon icon={faTrash} style={{color: "#ff0000",}} />
        </Button>
      );
    default:
      return  cellValue;
  }
}
  