import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@nextui-org/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function ModalSendMail({
  isOpen,
  onClose,
  titileModel,
  element,
  onSendMail,
  isLoading,
  isInvalid,
  email,
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titileModel}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <FontAwesomeIcon icon={faEnvelope} size="4x" style={{color: "#007bff"}}/>
              </div>
              <div className="text-center my-5">
                <span className="text-center">
                  ¿Esta seguro que desea enviar el correo de confirmación al usuario con email <strong>{email}</strong>?
                </span>
                <div className="flex justify-center items-center space-x-4 mt-5">
                  <Button
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isInvalid={isInvalid}
                    onPress={onClose}
                  >
                    No, cancelar
                  </Button>
                  <Button
                    color="primary"
                    onPress={() => onSendMail(email, onClose)}
                    isDisabled={isLoading}
                    isInvalid={isInvalid}
                  >
                    Si, enviar correo
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
} 
