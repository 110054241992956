import { useState } from "react";
import {
  getReservations,
  createReservation,
  updateReservation,
  deleteReservation,
  updateStatusReservation
} from "../../store/actions/reservationAction";
import { useDispatch, useSelector } from "react-redux";

const useReservations = (reservation) => {
  const dispatch = useDispatch();
  const [reservationSchema, setReservationSchema] = useState({
    id: reservation ? reservation.id : "",
    created_at: reservation ? reservation.created_at : "",
    created_by: reservation ? reservation.created_by : "",
    seats_reserved: reservation ? reservation.seats_reserved : "",
    client_name: reservation ? reservation.client_name : "",
    passenger_list: reservation ? reservation.passenger_list : [],
    confirmed: reservation ? reservation.confirmed : "",
    trip: reservation ? reservation.trip : "",
    payed: reservation ? reservation.payed : "",
    left_to_pay: reservation ? reservation.left_to_pay : "",
    client_type: reservation ? reservation.client_type : "",
    price: reservation ? reservation.price : null
  });

  const onSetForm = (type, value) => {
    setReservationSchema({
      ...reservationSchema,
      [type]: value,
    });
  };

  const onSetFormValues = (value) => {
    setReservationSchema({
      ...reservationSchema,
      ...value,
    });
  };

  const reservationsState = useSelector((state) => state.reservations);
  const {
    loadingReservation,
    errorReservation,
    reservations,
    loadingCreatingReservation,
    errorCreatingReservation,
    loadingDeletingReservation,
    errorDeletingReservation
  } = reservationsState;

  const fetchReservation = () => dispatch(getReservations());

  const onCreateNewReservation = (successCallback) => 
    dispatch(createReservation(reservationSchema, successCallback));
  

  const onUpdateReservation = (successCallback) =>
    dispatch(updateReservation(reservationSchema, successCallback));

  const onDeleteReservation = (id, successCallback) =>
    dispatch(deleteReservation(id, successCallback));

  const onConfirmReservation = (id, successCallback) =>
    dispatch(updateStatusReservation(id, successCallback));
  return {
    reservationSchema,
    onSetForm,
    onSetFormValues,
    reservations,
    fetchReservation,
    loadingReservation,
    errorReservation,
    onCreateNewReservation,
    loadingCreatingReservation,
    errorCreatingReservation,
    onUpdateReservation,
    loadingDeletingReservation,
    errorDeletingReservation,
    onDeleteReservation,
    onConfirmReservation
  };
};

export default useReservations;
