import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableDestinations from "./TopContentTableDestinations";
import BottomContentTableDestinations from "./BottomContentTableDestinations";
import {INITIAL_VISIBLE_COLUMNS_DESTINATION, STATUS_COLOR_MAP, COLUMNS_DESTINATIONS} from "../../constants/index"
import CellDestinations from "./CellDestinations";
import useDestinations from "../../hooks/destinations/useDestinations";
import { useSessions } from '../../hooks/useSessions'

export default function TableDestinations({
  setDestinationSelected,
  setValueDestinationAction,
  onOpen,
  onDeleteOpen,
  onPositionOpen
}) {
  const {
    destinations,
  } = useDestinations();
  const {
    publicUserData: { userRole },
  } = useSessions();
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS_DESTINATION)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_DESTINATIONS;

    return COLUMNS_DESTINATIONS.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredDestinations = [...destinations];

    if (hasSearchFilter) {
      filteredDestinations = filteredDestinations.filter((destination) =>
      destination.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_COLOR_MAP.length
    ) {
      filteredDestinations = filteredDestinations.filter((destination) =>
        Array.from(statusFilter).includes(destination.status)
      );
    }

    return filteredDestinations;
  }, [destinations, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateDestination = React.useCallback((destinationActionFlag, destination) => {
    setValueDestinationAction(destinationActionFlag);
    setDestinationSelected(destination);
    onOpen();
  });

  const deleteDestination = React.useCallback((destination) => {
    setDestinationSelected(destination);
    onDeleteOpen();
  });



  return (
    <>
    <div className="destinations__total">
        <TopContentTableDestinations
          editOrCreateDestination={editOrCreateDestination}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden destinations__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No destinations found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellDestinations userRole={userRole} destination={item} columnKey={columnKey} editOrCreateDestination={editOrCreateDestination} deleteDestination={deleteDestination}/>
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 destinations__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_DESTINATIONS}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No destinations found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellDestinations destination={item} userRole={userRole} columnKey={columnKey} editOrCreateDestination={editOrCreateDestination} deleteDestination={deleteDestination} /> 
                  </TableCell>

                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableDestinations
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
        </div>
    </>
  );
}
