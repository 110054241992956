// Vendors
import { useEffect } from "react";
import { useDispatch, useSelector  } from "react-redux";

// Router
import { BrowserRouter } from "react-router-dom";
import Router from './config/router';

// Components
import Navbar from "./components/navbar/MTNavbar";
import {NextUIProvider} from "@nextui-org/react";

// Actions
import { getSession } from "./store/actions/sessionActions";

export default function App() {
  const dispatch = useDispatch();
  const sessionStore = useSelector(state => state.session);
  const { sessionLoading, isLogged } = sessionStore;

  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  if (sessionLoading || isLogged === null) {
    return (<> cargando... </>)
  }

  return (
    <div className="App">
      <NextUIProvider>
        <BrowserRouter>
          <Navbar/>
          <Router />
        </BrowserRouter>
      </NextUIProvider>
    </div>
  );
}
