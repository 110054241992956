import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingTransfer: false,
  errorTransfer: false,
  transfers: [],
  loadingCreatingTransfer: false,
  errorCreatingTransfer: false,
  loadingDeletingTransfer: false,
  errorDeletingTransfer: false,
};

const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSFERS_FETCH_LOADING:
      return {
        ...state,
        loadingTransfer: true,
        errorTransfer: false,
      };
    case actionTypes.TRANSFERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingTransfer: false,
        transfers: action.payload,
        errorTransfer: false,
      };
    case actionTypes.TRANSFERS_FETCH_FAILURE:
      return {
        ...state,
        loadingTransfer: false,
        errorTransfer: true,
      };
    case actionTypes.TRANSFER_CREATE_LOADING:
      return {
        ...state,
        loadingCreatingTransfer: true,
        errorCreatingTransfer: false,
      };
    case actionTypes.TRANSFER_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreatingTransfer: false,
        errorCreatingTransfer: false,
      };
    case actionTypes.TRANSFER_CREATE_FAILURE:
      return {
        ...state,
        loadingCreatingTransfer: false,
        errorCreatingTransfer: true,
      };
    case actionTypes.TRANSFER_DELETE_LOADING:
      return {
        ...state,
        loadingDeletingTransfer: true,
        errorDeletingTransfer: false,
      };
    case actionTypes.TRANSFER_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletingTransfer: false,
        errorDeletingTransfer: false,
      };
    case actionTypes.TRANSFER_DELETE_FAILURE:
      return {
        ...state,
        loadingDeletingTransfer: false,
        errorDeletingTransfer: true,
      };
    default:
      return state;
  }
};

export default transferReducer;
