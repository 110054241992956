import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingReservation: false,
  errorReservation: false,
  reservations: [],
  loadingCreatingReservation: false,
  errorCreatingReservation: false,
  loadingDeletingReservation: false,
  errorDeletingReservation: false,
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESERVATIONS_FETCH_LOADING:
      return {
        ...state,
        loadingReservation: true,
        errorReservation: false,
      };
    case actionTypes.RESERVATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loadingReservation: false,
        reservations: action.payload,
        errorReservation: false,
      };
    case actionTypes.RESERVATIONS_FETCH_FAILURE:
      return {
        ...state,
        loadingReservation: false,
        errorReservation: true,
      };
    case actionTypes.RESERVATION_CREATE_LOADING:
      return {
        ...state,
        loadingCreatingReservation: true,
        errorCreatingReservation: false,
      };
    case actionTypes.RESERVATION_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreatingReservation: false,
        errorCreatingReservation: false,
      };
    case actionTypes.RESERVATION_CREATE_FAILURE:
      return {
        ...state,
        loadingCreatingReservation: false,
        errorCreatingReservation: true,
      };
    case actionTypes.RESERVATION_DELETE_LOADING:
      return {
        ...state,
        loadingDeletingReservation: true,
        errorDeletingReservation: false,
      };
    case actionTypes.RESERVATION_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletingReservation: false,
        errorDeletingReservation: false,
      };
    case actionTypes.RESERVATION_DELETE_FAILURE:
      return {
        ...state,
        loadingDeletingReservation: false,
        errorDeletingReservation: true,
      };
    default:
      return state;
  }
};

export default reservationReducer;
