// Vendors
import React from 'react'

// Component
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Spinner
} from "@nextui-org/react";
import { Toaster } from "react-hot-toast";

// Hooks
import { useSessions } from '../hooks/useSessions';

const ResetPassword = () => {
  const {
    resetPasswordLoading,
    resetPasswordError,
    setResetPasswordFormAction,
    resetPasswordAction,
  } = useSessions();

  return (
    <main className="reset-password__container flex items-center justify-center">
      <Toaster />
      <section>
        <Card className="reset-password__card">
          <CardHeader className="flex justify-center">
            <h1>Quieres cambiar la contraseña?</h1>
          </CardHeader>
          <CardBody>
            <p className="mb-5">
              Por favor, ingrese la nueva contraseña, luego repitala
            </p>
            <Input
              className="mb-5"
              label="Contraseña"
              variant="bordered"
              isDisabled={resetPasswordLoading}
              isInvalid={resetPasswordError}
              type='password'
              onChange={(e) =>
                setResetPasswordFormAction("newPassword", e.target.value)
              }
            />
            <Input
              className="mb-5"
              label="Repita la contraseña"
              variant="bordered"
              isDisabled={resetPasswordLoading}
              isInvalid={resetPasswordError}
              type='password'
              onChange={(e) =>
                setResetPasswordFormAction("newPasswordRepeat", e.target.value)
              }
            />
            <Button
              size="lg"
              color="primary"
              isDisabled={resetPasswordLoading}
              isLoading={resetPasswordLoading}
              onClick={() => resetPasswordAction()}
              spinner={<Spinner size="sm" />}
            >
              Continuar
            </Button>
          </CardBody>
        </Card>
      </section>
    </main>
  );
}

export default ResetPassword;
