import React from "react";
import {
  Input,
  Button,
  Textarea,
  Spinner,
} from "@nextui-org/react";
import { useDisclosure} from "@nextui-org/react";

import useDestinations from "../../hooks/destinations/useDestinations";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import ModalPosition from "../destinations/ModalPosition";

export default function FormCrudDestinations({
  readOnly,
  destination,
  onClose,
  destinationAction,
}) {
  const {
    onSetForm,
    onCreateNewDestination,
    onUpdateDestination,
    loadingCreatingDestination,
    errorCreatingDestination,
    destinationSchema,
  } = useDestinations(destination);
  let titleButtonPosition = '';
  destinationAction == 'edit' ? titleButtonPosition = 'Editar Ubicación' : titleButtonPosition = 'Agregar Ubicación';
  const handlePress = () => {
    destinationAction === "edit"
      ? onUpdateDestination(onClose)
      : onCreateNewDestination(onClose);
  };
  const { isOpen: isPositionOpen , onOpen: onPositionOpen, onClose: onPositionClose } = useDisclosure()
  const errorMessage = errorCreatingDestination ? (
    <div className="text-[#f31260]">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        style={{ color: "#f31260" }}
      />
      <span className="ml-1">Error al realizar la operacion</span>
    </div>
  ) : null;

  const inputsAreInvalid =
    !destinationSchema.name ||
    !destinationSchema.observation ||
    !destinationSchema.particular_price ||
    !destinationSchema.enterprise_price ||
    !destinationSchema.latitud ||
    !destinationSchema.distance;

  return (
    <form>
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={destinationSchema.name}
        onChange={(event) => onSetForm("name", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Nombre"
        isDisabled={loadingCreatingDestination}
        isInvalid={errorCreatingDestination}
      />
      <Input
        label="Precio estimado para particulares"
        className="abm-form__form-input"
        type="number"
        readOnly={readOnly}
        value={destinationSchema.particular_price}
        variant="bordered"
        onChange={(event) => onSetForm("particular_price", event.target.value)}
        isDisabled={loadingCreatingDestination}
        isInvalid={errorCreatingDestination}
        endContent={
          <div className="pointer-events-none flex items-center">
            <span className="text-default-400 text-small">$</span>
          </div>
        }
      />
      <Input
        label="Precio estimado para empresas"
        className="abm-form__form-input"
        type="number"
        readOnly={readOnly}
        value={destinationSchema.enterprise_price}
        variant="bordered"
        onChange={(event) => onSetForm("enterprise_price", event.target.value)}
        isDisabled={loadingCreatingDestination}
        isInvalid={errorCreatingDestination}
        endContent={
          <div className="pointer-events-none flex items-center">
            <span className="text-default-400 text-small">$</span>
          </div>
        }
      />
      <Input
        readOnly={readOnly}
        value={destinationSchema.distance}
        onChange={(event) => onSetForm("distance", event.target.value)}
        className="abm-form__form-input"
        type="number"
        variant="bordered"
        label="Distancia a recorrer (km)"
        isDisabled={loadingCreatingDestination}
        isInvalid={errorCreatingDestination}
      />
      {!readOnly &&
        <div className="flex flex-col gap-1 ">
          <div className="destinations__position-button">
            <Button color="primary" onClick={onPositionOpen}>
              {titleButtonPosition}
            </Button>
          </div>
        </div>
      }
      {readOnly &&
        <div className="flex flex-col gap-1 ">
          <div className="destinations__position-button">
            <Button color="primary" onClick={onPositionOpen}>
              Ver Ubicación
            </Button>
          </div>
        </div>
      }
      <ModalPosition
        isOpen={isPositionOpen}
        onClose={onPositionClose}
        onSetForm={onSetForm}
        titleModel={'Ubicación'}
        fieldFlagReadOnly={readOnly}
        destinationSelected={destinationSchema}
      />
      <Textarea
        label="Observacion"
        readOnly={readOnly}
        value={destinationSchema.observation}
        variant="bordered"
        onChange={(event) => onSetForm("observation", event.target.value)}
        isDisabled={loadingCreatingDestination}
        isInvalid={errorCreatingDestination}
      />
      <div>{errorMessage}</div>

      <div className="flex flex-col gap-1 w-full">
        <div className="destinations__save-button">
          <Button
            color="danger"
            variant="light"
            onPress={onClose}
            isDisabled={loadingCreatingDestination}
          >
            Cerrar
          </Button>
          {!readOnly && (
            <Button
              color="primary"
              onPress={handlePress}
              isLoading={loadingCreatingDestination}
              isDisabled={loadingCreatingDestination || inputsAreInvalid}
              spinner={<Spinner size="sm" />}
            >
              Guardar
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
