import React from 'react'
import LogInForm from '../components/login/LogInForm'

export default function LogIn() {
  return (
    <div>
      <LogInForm/>
    </div>
  )
}
