import actionTypes from "../../constants/actionTypes";

const initialState = {
  loadingReport: false,
  errorReport: false,
  report: null,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_SEND_REQUEST_LOADING:
      return {
        ...state,
        loadingReport: true,
        errorReport: false,
      };
    case actionTypes.REPORT_SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        report: action.payload,
        errorReport: false,
      };
    case actionTypes.REPORT_SEND_REQUEST_FAILURE:
      return {
        ...state,
        loadingReport: false,
        errorReport: true,
      };
    default:
      return state;
  }
}

export default reportsReducer;