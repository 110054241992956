import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";

const getDriversLoading = () => ({
  type: actionTypes.DRIVERS_FETCH_LOADING,
});

const getDriversSuccess = (data) => ({
  type: actionTypes.DRIVERS_FETCH_SUCCESS,
  payload: data,
});

const getDriversFailure = () => ({
  type: actionTypes.DRIVERS_FETCH_FAILURE,
});

const getDriver = () => async (dispatch) => {
  dispatch(getDriversLoading());
  let { data, error } = await supabase
    .from("driver")
    .select(
      `id,
    lastname,
    firstname,
    email,
    is_deleted`
    )
    .eq("is_deleted", false);
  if (!error) {
    dispatch(getDriversSuccess(data));
  } else {
    toast.error("Error al obtener los conductores");
    dispatch(getDriversFailure());
  }
};

const createDriverLoading = () => ({
  type: actionTypes.DRIVER_CREATE_LOADING,
});

const createDriverSuccess = () => ({
  type: actionTypes.DRIVER_CREATE_SUCCESS,
});

const createDriverFailure = () => ({
  type: actionTypes.DRIVER_CREATE_FAILURE,
});

const createDriver = (formData, successCallback) => async (dispatch) => {
  dispatch(createDriverLoading());
  const { error } = await supabase
    .from("driver")
    .insert([
      {
        firstname: formData.firstname.trim(),
        lastname: formData.lastname.trim(),
        email: formData.email.trim()
      },
    ])
    .select();
  if (!error) {
    dispatch(createDriverSuccess());
    successCallback();
    dispatch(getDriver());
  } else {
    dispatch(createDriverFailure());
  }
};

const updateDriver = (formData, successCallback) => async (dispatch) => {
  dispatch(createDriverLoading());
  const { data, error } = await supabase
    .from("driver")
    .update({ firstname: formData.firstname.trim(),
      lastname: formData.lastname.trim(),
      email:  formData.email.trim()
    })
    .eq('id', parseInt(formData.id))
    .select();
  if (!error) {
    dispatch(createDriverSuccess());
    successCallback();
    dispatch(getDriver());
  } else {
    dispatch(createDriverFailure());
  }
}

const deleteDriverLoading = () => ({
  type: actionTypes.DRIVER_DELETE_LOADING,
});

const deleteDriverSuccess = () => ({
  type: actionTypes.DRIVER_DELETE_SUCCESS,
});

const deleteDriverFailure = () => ({
  type: actionTypes.DRIVER_DELETE_FAILURE,
});


const deleteDriver = (id, successCallback) => async (dispatch) => {
  dispatch(deleteDriverLoading());
  const { error } = await supabase.from("driver")
    .update({ 
      is_deleted: true,
    })
    .eq("id", id);
  if (!error) {
    successCallback();
    dispatch(deleteDriverSuccess());
    dispatch(getDriver());
  } else {
    dispatch(deleteDriverFailure());
  }
}

export { getDriver, createDriver, updateDriver, deleteDriver }
