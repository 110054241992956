import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody
} from "@nextui-org/react";
import FormCrudDestinations from "./FormCrudDestinations";

export default function ModalDestinationsData({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  destinationSelected,
  destinationAction,
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titileModel}
            </ModalHeader>
            <ModalBody>
              <div>
                <FormCrudDestinations
                  destination={destinationSelected}
                  readOnly={fieldFlagReadOnly}
                  destinationAction={destinationAction}
                  onClose={onClose}
                />
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
