import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";


const getTravelsLoading = () => ({
  type: actionTypes.TRAVELS_FETCH_LOADING,
});

const getTravelsSuccess = (data) => ({
  type: actionTypes.TRAVELS_FETCH_SUCCESS,
  payload: data,
});

const getTravelsFailure = () => ({
  type: actionTypes.TRAVELS_FETCH_FAILURE,
});

const getTravels = () => async (dispatch) => {
  dispatch(getTravelsLoading());
  let { data, error } = await supabase.from("trip").select(
    `*,
    destination(*),
    vehicle(*),
    driver(*),
    start_date,
    end_date,
    status,
    reservations(*)
    `);
  if (!error) {
    dispatch(getTravelsSuccess(data));
  } else {
    dispatch(getTravelsFailure());
  }
};

const createTravelLoading = () => ({
  type: actionTypes.TRAVEL_CREATE_LOADING,
});

const createTravelSuccess = () => ({
  type: actionTypes.TRAVEL_CREATE_SUCCESS,
});

const createTravelFailure = () => ({
  type: actionTypes.TRAVEL_CREATE_FAILURE,
});

const addPassengers = (formData, successCallback) => async (dispatch) =>{
  dispatch(createTravelLoading());
  const { error } = await supabase
    .from("trip")
    .insert([
      {
        destination_id: formData.destination_id,
        vehicle_id: formData.vehicle_id,
        driver_id: formData.driver_id,
        created_by: formData.created_by,
        start_date: formData.start_date.toLocaleString('en-US'),
        end_date: formData.end_date.toLocaleString('en-US')
      },
    ])
    .select();
  if (!error) {
    dispatch(createTravelSuccess());
    successCallback();
    dispatch(getTravels());
  } else {
    dispatch(createTravelFailure());
  }
} 

const createTravel = (formData, successCallback) => async (dispatch) => {
  dispatch(createTravelLoading());
  const { error } = await supabase
    .from("trip")
    .insert([
      {
        destination_id: formData.destination_id,
        vehicle_id: formData.vehicle_id,
        driver_id: formData.driver_id,
        created_by: formData.created_by,
        available_seats: formData.available_seats,
        start_date: formData.start_date.toLocaleString('en-US'),
        end_date: formData.end_date.toLocaleString('en-US'),
        status: 'open'
      },
    ])
    .select();
  if (!error) {
    dispatch(createTravelSuccess());
    successCallback();
    dispatch(getTravels());
  } else {
    dispatch(createTravelFailure());
  }
};

const updateTravel = (formData, successCallback) => async (dispatch) => {
  dispatch(createTravelLoading());
  const { error } = await supabase
    .from("trip")
    .update({
      destination_id: formData.destination_id,
      vehicle_id: formData.vehicle_id,
      driver_id: formData.driver_id,
      created_by: formData.created_by,
      start_date: formData.start_date.toLocaleString('en-US'),
      end_date: formData.end_date.toLocaleString('en-US')
    })
    .eq("id", parseInt(formData.id))
    .select();
  if (!error) {
    dispatch(createTravelSuccess());
    successCallback();
    dispatch(getTravels());
  } else {
    dispatch(createTravelFailure());
  }
}

const deleteTravelLoading = () => ({
  type: actionTypes.TRAVEL_DELETE_LOADING,
});

const deleteTravelSuccess = () => ({
  type: actionTypes.TRAVEL_DELETE_SUCCESS,
});

const deleteTravelFailure = () => ({
  type: actionTypes.TRAVEL_DELETE_FAILURE,
});


const deleteTravel = (id, successCallback) => async (dispatch) => {
  dispatch(deleteTravelLoading());
  const { error } = await supabase.from("trip").delete().eq("id", id);
  if (!error) {
    successCallback();
    dispatch(deleteTravelSuccess());
    dispatch(getTravels());
  } else {
    dispatch(deleteTravelFailure());
  }
}

export { getTravels, createTravel, updateTravel, deleteTravel }
