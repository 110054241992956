import React from "react";
import { Input, Button } from "@nextui-org/react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import { COLUMNS_PASSENGERS, CLIENT_TYPE, ROLE_CONFIRM_RESERVATION } from "../../constants/index";
import CellPassengers from "./CellPassengers";
import useReservations from "../../hooks/reservations/useReservations";
import { useSessions } from '../../hooks/useSessions';
import useTravels from "../../hooks/travels/useTravels";
import useDestinations from "../../hooks/destinations/useDestinations";


export default function FormAddPassengers({ readOnly, reservation, trip, onClose, showReservations }) {
  const {
    onSetForm,
    onCreateNewReservation,
    onUpdateReservation,
    loadingCreatingReservation,
    errorCreatingReservation,
    reservationSchema,
  } = useReservations(reservation);
  const { userInformation } = useSessions();
  const { travels } = useTravels();
  const { destinations } = useDestinations();
  const [passengers, setPassengers] = React.useState(reservationSchema.passenger_list);
  const [formPassenger, setPassengerForm] = React.useState(reservationSchema.passenger_list);
  const [availableSeats, setAvailableSeats] = React.useState();
  const [labelAvailableSeats, setLabelAvailableSeats] = React.useState();


  let travel;
  let destination;
  let priceSuggested = 'No hay precio sugerido';
  let columns_passengers_show = reservation ? COLUMNS_PASSENGERS.filter(column => column.key != 'button-delete') :  COLUMNS_PASSENGERS;
  
  const addNewPassengers = React.useCallback((passenger) => {
    if((travel.available_seats - passengers.length) > 0){
      setPassengers([...passengers, passenger]);
      setPassengerForm({namePassenger: "", surnamePassenger: "", dniPassenger: ""});
    }else{
      setLabelAvailableSeats('La capacidad del transfer esta llena')
    }
  });

  React.useEffect(() => {
    reservationSchema.passenger_list = passengers;
    if(passengers){
      reservationSchema.seats_reserved = passengers.length;
    }
  }, [passengers]);

  React.useEffect(() => {
    travel = travels.find( travel => travel.id == trip);
    if(travel){
      if(travel.available_seats == 0){
        setAvailableSeats(false);
      }else{
        setAvailableSeats(true);
      }
    }
  }, [trip]);
  
  React.useEffect(() => {  
    if(trip){
      reservationSchema.trip = trip.toString();
    }
    if(ROLE_CONFIRM_RESERVATION.includes(userInformation.user_metadata.role)){
      reservationSchema.confirmed = 'TRUE';
      onSetForm('confirmed', 'TRUE');
    }else{
      reservationSchema.confirmed = false;
      onSetForm('confirmed', false);
    }
    onSetForm('created_by', userInformation.id);
    readOnly = false;
  }, [])

  const handlePress = () => {
    onCreateNewReservation(onClose);
  };

  const getPrice = (clientType)=>{
    travel = travels.find( travel => travel.id == trip);
    destination = destinations.find(destination => destination.id == travel.destination_id)
    if(clientType == 'company' && destination.enterprise_price){
      priceSuggested = 'El precio sugerido es: ' + destination.enterprise_price.toString()
    }else if(clientType == 'individual' && destination.particular_price){
      priceSuggested = 'El precio sugerido es: ' + destination.particular_price.toString()
    }else{
      priceSuggested = 'No hay precio sugerido';
    }
    return priceSuggested
  }

  const removePassenger = (passenger) => {
    const passengersFiltered = passengers.filter( p => p.dniPassenger != passenger.dniPassenger)
    setPassengers([...passengersFiltered]);
    setLabelAvailableSeats('')
  };

  return (
    <>
      {
        showReservations && 
        <div className="max-h-[32rem] overflow-y-auto p-4">
          <Table >
            <TableHeader columns={columns_passengers_show}>
              {(column) => (
                <TableColumn key={column.key}>{column.label}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={passengers}>
            {passengers.map((item) => (
                <TableRow key={item.dniPassenger}>
                  {(columnKey) => (
                    <TableCell>
                      {
                        <CellPassengers
                          passenger={item}
                          columnKey={columnKey}
                          removePassenger={removePassenger}
                        />
                      }
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      }
      {!showReservations && availableSeats && (
        <div className="max-h-[32rem] overflow-y-auto p-4">
          <span>{labelAvailableSeats}</span>
          <div>
            <p class="capitalize md:normal-case">Cliente</p>
            <div className="flex items-stretch space-x-4">
            <Input
                defaultValue=" "
                isReadOnly={readOnly}
                isDisabled={readOnly}
                value={reservationSchema.client_name}
                onChange={(event) =>
                  onSetForm("client_name", event.target.value )
                }
                className="abm-form__form-input"
                type="text"
                variant="bordered"
                label="Nombre del cliente"
              />
              <Select
                label="Tipo de cliente"
                className="max-w-xs"
                isDisabled={readOnly}
                defaultSelectedKeys={[reservationSchema.client_type]}
                onChange={(event) =>
                  onSetForm("client_type", event.target.value )
                }
              >
                {CLIENT_TYPE.map((clientType) => (
                  <SelectItem key={clientType.value} value={reservationSchema.client_type} >
                    {clientType.label}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="flex items-stretch space-x-4">
            <Input
                defaultValue=" "
                value={reservationSchema.price}
                isReadOnly={readOnly}
                isDisabled={readOnly}
                onChange={(event) =>
                  onSetForm("price", event.target.value )
                }
                className="abm-form__form-input"
                type="number"
                variant="bordered"
                label="Precio"
              />
              <p class="capitalize md:normal-case">{getPrice(reservationSchema.client_type)}</p>
            </div>
          </div>
          <p class="capitalize md:normal-case">Pasajeros</p>
          {!readOnly && 
          <div className="flex items-stretch space-x-4">
            <Input
              defaultValue=" "
              value={formPassenger.namePassenger}
              onChange={(event) => setPassengerForm({...formPassenger, namePassenger: event.target.value })}
              className="abm-form__form-input"
              isReadOnly={readOnly}
              isDisabled={readOnly}
              type="text"
              variant="bordered"
              label="Nombre"
            />
            <Input
              defaultValue=" "
              value={formPassenger.surnamePassenger}
              onChange={(event) => setPassengerForm({...formPassenger, surnamePassenger: event.target.value })}
              className="abm-form__form-input"
              type="text"
              isReadOnly={readOnly}
              variant="bordered"
              label="Apellido"
            />

            <Input
              isReadOnly={readOnly}
              defaultValue=" "
              value={formPassenger.dniPassenger}
              onChange={(event) => setPassengerForm({...formPassenger, dniPassenger: event.target.value })}
              className="abm-form__form-input"
              type="text"
              variant="bordered"
              label="DNI"
            />
          </div>}
          <div className="flex items-end justify-end mb-5">
            { !readOnly &&
            <Button
              color="primary"
              onClick={() => addNewPassengers(formPassenger)}
            >
              Agregar
            </Button>}
          </div>

          <Table >
            <TableHeader columns={columns_passengers_show}>
              {(column) => (
                <TableColumn key={column.key}>{column.label}</TableColumn>
              )}
            </TableHeader>
            <TableBody items={passengers}>
            {passengers.map((item) => (
                <TableRow key={item.dniPassenger}>
                  {(columnKey) => (
                    <TableCell>
                      {
                        <CellPassengers
                          passenger={item}
                          columnKey={columnKey}
                          removePassenger={removePassenger}
                        />
                      }
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="flex items-end justify-end mb-5">
            { !readOnly && <Button
              color="primary"
              onPress={handlePress}
            >
              Confirmar
            </Button>}
          </div>
        </div>
      )}
      {!availableSeats && (
        <div className="max-h-[32rem] overflow-y-auto p-4">
          <span>No hay asientos disponibles</span>
        </div>
      )}
    </>
  );
}
