import { useState } from "react";
import {
  getTransfers,
  createTransfer,
  updateTransfer,
  deleteTransfer
} from "../../store/actions/transferActions";
import { useDispatch, useSelector } from "react-redux";

const useTransfers = (transfer) => {
  const dispatch = useDispatch();
  const [transferSchema, setTransferSchema] = useState({
    id: transfer ? transfer.id : "",
    licensePlate: transfer ? transfer.license_plate : "",
    brand: transfer ? transfer.brand : "",
    model: transfer ? transfer.model : "",
    year: transfer ? transfer.year : "",
    capacity: transfer ? transfer.capacity : "",
    status: transfer ? transfer.status : "",
    observations: transfer ? transfer.observations : "",
    suitableForDisabledPeople: transfer ? transfer.suitable_for_disabled_people : false,
  });

  const onSetForm = (type, value) => {
    setTransferSchema({
      ...transferSchema,
      [type]: value,
    });
  };

  const transfersState = useSelector((state) => state.transfers);
  const {
    loadingTransfer,
    errorTransfer,
    transfers,
    loadingCreatingTransfer,
    errorCreatingTransfer,
    loadingDeletingTransfer,
    errorDeletingTransfer
  } = transfersState;

  const fetchTransfer = () => dispatch(getTransfers());

  const onCreateNewTransfer = (successCallback) =>
    dispatch(createTransfer(transferSchema, successCallback));

  const onUpdateTransfer = (successCallback) =>
    dispatch(updateTransfer(transferSchema, successCallback));

  const onDeleteTransfer = (id, successCallback) =>
    dispatch(deleteTransfer(id, successCallback));

  return {
    transferSchema,
    onSetForm,
    transfers,
    fetchTransfer,
    loadingTransfer,
    errorTransfer,
    onCreateNewTransfer,
    loadingCreatingTransfer,
    errorCreatingTransfer,
    onUpdateTransfer,
    loadingDeletingTransfer,
    errorDeletingTransfer,
    onDeleteTransfer,
  };
};

export default useTransfers;
