// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

//Actions
import {
  createPayment,
  deletePayment,
  getPaymentsByReservation,
} from "../../store/actions/paymentActions";

function usePayments(payment) {
  const dispatch = useDispatch();
  const [paymentSchema, setPaymentSchema] = useState({
    id: payment ? payment.id : "",
    reservationId: payment ? payment.reservationId : "",
    installment: payment ? payment.installment : "",
    paymentType: payment ? payment.payment_type : "",
    created_at: payment ? payment.created_at : ""
  });
  const onSetForm = (type, value) => {
    setPaymentSchema({
      ...paymentSchema,
      [type]: value,
    });
  };

  const paymentsState = useSelector((state) => state.payments);
  const { 
    payments,
    getPaymentsLoading,
    getPaymentsError,
    createPaymentLoading,
    createPaymentError,
    deletePaymentLoading,
    deletePaymentError,
  } = paymentsState;

  const onFetchPayments = (reservationId) =>
    dispatch(getPaymentsByReservation(reservationId));
  
  const onCreateNewPayment = (successCallback) =>
    dispatch(createPayment(paymentSchema, payment.reservationId, successCallback));
  
  const onDeletePayment = (id, successCallback) =>
    dispatch(deletePayment(id, successCallback));

  return {
    payments,
    getPaymentsLoading,
    getPaymentsError,
    createPaymentLoading,
    createPaymentError,
    deletePaymentLoading,
    deletePaymentError,
    onFetchPayments,
    onCreateNewPayment,
    onDeletePayment,
    onSetForm,
    paymentSchema,
  }
}

export default usePayments