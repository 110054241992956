import { useState }from 'react'

export const useLogInForm = () => {
  const [logInForm, setlogInForm] = useState({
    email: "",
    password: "",
  });

  const setForm = (type, value) => {
    setlogInForm({
      ...logInForm,
      [type]: value,
    });
  };

  return { logInForm, setForm };
}
