import { useState } from "react";
import {
  getDriver,
  createDriver,
  updateDriver,
  deleteDriver,
} from "../../store/actions/driverActions";
import { useDispatch, useSelector } from "react-redux";

const useDrivers = (driver) => {
  const dispatch = useDispatch();
  const [driverSchema, setDriverSchema] = useState({
    id: driver ? driver.id : "",
    firstname: driver ? driver.firstname : "",
    lastname: driver ? driver.lastname : "",
    email: driver ? driver.email : ""
  });

  const onSetForm = (type, value) => {
    setDriverSchema({
      ...driverSchema,
      [type]: value,
    });
  };

  const driverState = useSelector((state) => state.drivers);
  const {
    drivers,
    loadingDriver,
    errorDriver,
    loadingCreatingDriver,
    errorCreatingDriver,
    loadingDeletingDriver,
    errorDeletingDriver,
  } = driverState;
  const fetchDriver = () => dispatch(getDriver());

  const onCreateNewDriver = (successCallback) =>
    dispatch(createDriver(driverSchema, successCallback));

  const onUpdateDriver = (successCallback) =>
    dispatch(updateDriver(driverSchema, successCallback));

  const onDeleteDriver = (id, successCallback) =>
    dispatch(deleteDriver(id, successCallback));

  return {
    loadingDriver,
    drivers,
    fetchDriver,
    onCreateNewDriver,
    onSetForm,
    loadingDriver,
    loadingCreatingDriver,
    errorDriver,
    errorCreatingDriver,
    drivers,
    driverSchema,
    onUpdateDriver,
    onDeleteDriver,
    loadingDeletingDriver,
    errorDeletingDriver
  };
};

export default useDrivers;
