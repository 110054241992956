import React from "react";
import { Input, Button, Spacer } from "@nextui-org/react";
import { CustomCheckbox } from "../commons/CustomCheckBox";

export default function FormCrudUsers({
  readOnly,
  user,
  onClose,
  formUser,
  setForm,
  onSendForm,
  registerLoading,
  registerError,
  userAction,
}) {
  return (
    <div id="root">
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={formUser.email}
        onChange={(event) => setForm("email", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="E-mail"
        isDisabled={registerLoading}
        isInvalid={registerError}
      />
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={formUser.firstName}
        onChange={(event) => setForm("firstName", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Nombre"
        isDisabled={registerLoading}
        isInvalid={registerError}
      />
      <Input
        readOnly={readOnly}
        defaultValue=" "
        value={formUser.lastName}
        onChange={(event) => setForm("lastName", event.target.value)}
        className="abm-form__form-input"
        type="text"
        variant="bordered"
        label="Apellido"
        isDisabled={registerLoading}
        isInvalid={registerError}
      />
      <div className="flex flex-col gap-1 w-full">
        <div className="flex">
          <CustomCheckbox
            onChange={() => setForm("role", "admin")}
            isSelected={formUser.role === "admin"}
            value="admin"
            isDisabled={registerLoading}
          >
            Administrador
          </CustomCheckbox>
          <Spacer />
          <CustomCheckbox
            isSelected={formUser.role === "transfer"}
            value="transfer"
            onChange={() => setForm("role", "transfer")}
            isDisabled={registerLoading}
          >
            Transfer
          </CustomCheckbox>
          <Spacer />
          <CustomCheckbox
            isSelected={formUser.role === "tourist"}
            value="tourist"
            onChange={() => setForm("role", "tourist")}
            isDisabled={registerLoading}
          >
            Turista
          </CustomCheckbox>
        </div>
        <div className="users__save-button">
          <Button color="danger" variant="light" onPress={onClose}>
            Cerrar
          </Button>
          {!readOnly && (
            <Button
              isDisabled={registerLoading}
              color="primary"
              onPress={() => onSendForm(onClose, userAction)}
            >
              Guardar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
