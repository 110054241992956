import { useSelector, useDispatch } from "react-redux";
import {
  logIn,
  logOut,
  forgotPassword,
  resetPassword,
  getUsers,
  userDelete,
  sendConfirmationMail,
} from "../store/actions/sessionActions";

import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useSessions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLogged,
    logInLoading,
    logInError,
    forgotPasswordLoading,
    forgotPasswordError,
    forgotPasswordSuccess,
    resetPasswordLoading,
    resetPasswordError,
    sessionInformation,
    userInformation,
    publicUserData,
    registerLoading,
    registerError,
    getUsersLoading,
    getUsersError,
    deleteUserLoading,
    deleteUserError,
    sendConfirmationMailLoading,
    sendConfirmationMailError,
    users,
  } = useSelector((state) => state.session);

  const [resetPasswordForm, setResetPasswordForm] = useState({
    newPassword: '',
    newPasswordRepeat: '',
  });

  const setResetPasswordFormAction = (type, value) => {
    setResetPasswordForm({
      ...resetPasswordForm,
      [type]: value,
    });
  };

  const [forgotPasswordMail, setForgotPasswordMail] = useState('');

  const onLogIn = (logForm) => dispatch(logIn(logForm, navigate));
  const onLogOut = () => dispatch(logOut());
  const resetPasswordAction = () => dispatch(resetPassword(resetPasswordForm, navigate));
  const forgotPasswordAction = () => dispatch(forgotPassword(forgotPasswordMail));
  const getUsersAction = () => dispatch(getUsers(forgotPasswordMail));
  const userDeleteAction = (id, callback) => dispatch(userDelete(id, callback));
  const sendConfirmationMailAction = (email, callback) => dispatch(sendConfirmationMail(email, callback));

  return {
    isLogged,
    logInLoading,
    logInError,
    onLogOut,
    onLogIn,
    forgotPasswordLoading,
    forgotPasswordError,
    forgotPasswordSuccess,
    forgotPasswordAction,
    forgotPasswordMail,
    setForgotPasswordMail,
    resetPasswordLoading,
    resetPasswordError,
    resetPasswordAction,
    setResetPasswordFormAction,
    deleteUserError,
    deleteUserLoading,
    sessionInformation,
    userInformation,
    publicUserData,
    registerLoading,
    registerError,
    getUsersAction,
    getUsersLoading,
    getUsersError,
    userDeleteAction,
    sendConfirmationMailAction,
    sendConfirmationMailLoading,
    sendConfirmationMailError,
    users,
  };
};
