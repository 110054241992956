import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@nextui-org/react";
import FormMapPosition from "./FormMapPosition";



export default function ModalPosition({
  isOpen,
  onClose,
  titleModel,
  fieldFlagReadOnly,
  destinationSelected,
  destinationAction,
  onSetForm
}) {
  return (
    <Modal
    Backdrop={"transparent"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titleModel}
            </ModalHeader>
            <ModalBody>
              <div>
                <FormMapPosition
                readOnly={fieldFlagReadOnly}
                destination={destinationSelected}
                onClose={onClose}
                onSetForm={onSetForm}
                destinationAction={destinationAction}
                />
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
