import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";
import {STATUS_COLOR_MAP, COLUMNS_STATUS_TRAVELS} from "../../constants/index"
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";
import useTravels from "../../hooks/travels/useTravels";
import moment from 'moment-timezone';
import 'moment/locale/es';


  export default function CellTravels({travel, columnKey, editOrCreateTravel, deleteTravel}) {
    const { travels } = useTravels();
    const cellValue = travel[columnKey];
    const travelSelected = travels.find(travelItem => travel.id == travelItem.id);
    switch (columnKey) {
      case "status":
        return (

          <Chip
            className="capitalize"
            color={STATUS_COLOR_MAP[travel.status]}
            size="sm"
            variant="flat"
          >
            {COLUMNS_STATUS_TRAVELS[cellValue]}
          </Chip>
        );
      case "driver":
        const driverName = cellValue ? `${cellValue.firstname}  ${cellValue.lastname}` : '';
        return driverName
      case "transfer":
        const transferData = cellValue ? `${cellValue.brand}  ${cellValue.model} (${cellValue.license_plate})` : ''
        return transferData
      case "end_date":
          moment.locale('es');
          return moment(cellValue).format('LLL');
      case "start_date":
        moment.locale('es');
        return moment(cellValue).format('LLL');
      case "actions":
        return (

          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem onPress={() => editOrCreateTravel("view", travelSelected)}>
                  Ver
                </DropdownItem>
                <DropdownItem onPress={() => editOrCreateTravel("edit", travelSelected)}>
                  Editar
                </DropdownItem>
                <DropdownItem onPress={() => editOrCreateTravel("addpassangers", travelSelected)}>
                  Agregar reserva
                </DropdownItem>
                <DropdownItem onPress={() => editOrCreateTravel("link", travelSelected)}>
                  Ver reserva
                </DropdownItem>
                <DropdownItem onPress={() => deleteTravel(travel.id)}>Eliminar</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return  cellValue;
    }
  }
  