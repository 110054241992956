import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@nextui-org/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function ModalDelete({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  element,
  onDelete,
  isLoading,
  isInvalid,
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Eliminar {titileModel}
            </ModalHeader>
            <ModalBody>
              <div className="text-center">
                <FontAwesomeIcon icon={faCircleXmark} size="4x" style={{color: "#f31260"}}/>
              </div>
              <div className="text-center my-5">
                <span className="text-center">
                  ¿Esta seguro que desea eliminar el elemento seleccionado?
                </span>
                <div className="flex justify-center items-center space-x-4 mt-5">
                  <Button
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isInvalid={isInvalid}
                    onPress={onClose}
                  >
                    No, mantener
                  </Button>
                  <Button
                    color="danger"
                    onPress={() => onDelete(element, onClose)}
                    isDisabled={isLoading}
                    isInvalid={isInvalid}
                  >
                    Si, eliminar
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
