//Vendors
import React, { useEffect } from "react";

//Hooks
import { useDisclosure } from "@nextui-org/react";
import { useSessions } from "../hooks/useSessions";
import { useRegisterUserForm } from "../hooks/users/useRegisterUserForm";

//Components
import ModalUserData from "../components/users/ModalUserData";
import TableUsers from "../components/users/TableUsers";
import ModalDelete from "../components/commons/ModalDelete"
import RoleViewHandler from "../components/commons/RoleViewHandler";
import CRUDSkeleton from "../components/commons/CRUDSkeleton";
import CRUDRetryComponent from "../components/commons/CRUDRetryComponent";
import ModalSendMail from "../components/users/ModalSendMail";


//Constants
import { ADMIN_ROLE } from '../constants';


export default function Users() {
  const {
    getUsersAction,
    getUsersLoading,
    getUsersError,
    userDeleteAction,
    deleteUserError,
    deleteUserLoading,
    sendConfirmationMailAction,
    sendConfirmationMailLoading,
    sendConfirmationMailError,
  } = useSessions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen , onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen: isSendMailOpen , onOpen: onSendMailOpen, onClose: onSendMailClose } = useDisclosure()
  const [userAction, setValueUserAction] = React.useState("");
  const titileModel =
    userAction === "create"
      ? "Crear usuario"
      : userAction === "edit"
      ? "Editar usuario"
      : "Usuario";
  let fieldFlagReadOnly = true;
  if (userAction === "create" || userAction === "edit") {
    fieldFlagReadOnly = false;
  }

  const {
    formUser,
    setForm,
    onSendForm,
    registerLoading,
    registerError,
    setUserSelected,
  } = useRegisterUserForm();

  const [userSelected] = React.useState({
    id: "",
    name: "",
    email: "",
  });

  useEffect(() => {
    getUsersAction();
  }, [])

  if (getUsersLoading) {
    return <CRUDSkeleton />;
  }

  if (getUsersError) {
    return <CRUDRetryComponent onRetryClick={() => getUsersAction()} type="Usuarios" />;
  }

  return (
    <>
      <RoleViewHandler roleList={[ADMIN_ROLE]}>
        <ModalDelete
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          titileModel={titileModel}
          element={formUser.id}
          onDelete={userDeleteAction}
          isLoading={deleteUserLoading}
          isInvalid={deleteUserError}
          />
        <ModalSendMail
          isOpen={isSendMailOpen}
          onClose={onSendMailClose}
          titileModel={"Enviar correo de confirmación"}
          onSendMail={sendConfirmationMailAction}
          isLoading={sendConfirmationMailLoading}
          isInvalid={sendConfirmationMailError}
          element={formUser.id}
          email={formUser.email}
        />
        <ModalUserData
          isOpen={isOpen}
          onClose={onClose}
          titileModel={titileModel}
          fieldFlagReadOnly={fieldFlagReadOnly}
          userSelected={userSelected}
          formUser={formUser}
          setForm={setForm}
          onSendForm={onSendForm}
          registerLoading={registerLoading}
          registerError={registerError}
          userAction={userAction}
        />
        <TableUsers
          setUserSelected={setUserSelected}
          setValueUserAction={setValueUserAction}
          onOpen={onOpen}
          onDeleteOpen={onDeleteOpen}
          onSendMailOpen={onSendMailOpen}
        />
      </RoleViewHandler>
    </>
  );
}
