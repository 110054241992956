// Hooks
import { useEffect } from 'react'
import { useSessions } from '../../hooks/useSessions'
import { useNavigate } from "react-router-dom";

const RoleViewHandler = ({roleList, children}) => {
  const { publicUserData: {userRole} }  = useSessions();
  const navigate = useNavigate();
  useEffect(() => {
    if (!roleList.includes(userRole)) {
      navigate('/')
    }
  }, []);

  return (
    <>
      {children}
    </>
  )
}

export default RoleViewHandler
