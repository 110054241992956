import { Modal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";
import FormCrudTransfers from "./FormCrudTransfers";
import {Textarea} from "@nextui-org/react";

export default function ModalTransfersData({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  transferSelected,
  transferAction,
  onlyDescription,
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      {!onlyDescription && (
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {titileModel}
              </ModalHeader>
              <ModalBody>
                <div>
                  <FormCrudTransfers
                    transfer={transferSelected}
                    readOnly={fieldFlagReadOnly}
                    onClose={onClose}
                    transferAction={transferAction}
                  />
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      )}
      {onlyDescription && (
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {titileModel}
              </ModalHeader>
              <ModalBody>
                <div>
                <Textarea
                  label="Observación"
                  value={transferSelected.description}
                  className="max-w-xs"
                />
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      )}
    </Modal>
  );
}
