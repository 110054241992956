import React from "react";
import { Skeleton } from "@nextui-org/react";

const DayColumn = () => (
  <div className="col-span-1 flex justify-end h-10">
    <Skeleton className="rounded-lg w-8 h-5" />
  </div>
);

const CalendarSkeleton = () => {
  const daysRow = (
    <div className="grid grid-cols-7 grid-rows-2">
      <DayColumn />
      <DayColumn />
      <DayColumn />
      <DayColumn />
      <DayColumn />
      <DayColumn />
      <DayColumn />
    </div>
  );

  return (
    <div className="home-calendar__container">
      <div className="calendar-header__row mb-1">
        <div className="calendar-header__container w-full max-w-[1024px]">
          <div className="calendar-header__left-panel flex">
            <div className="calendar-header__title capitalize">
              <Skeleton className="rounded-lg w-24 h-8 mr-5" />
            </div>
            <Skeleton className="rounded-lg w-8 h-8 mr-2" />
            <Skeleton className="rounded-lg w-8 h-8" />
          </div>
          <div className="calendar-header__right-panel flex">
            <Skeleton className="rounded-lg w-16 h-8 mr-3" />
            <Skeleton className="rounded-lg w-16 h-8" />
          </div>
        </div>
      </div>
      <div className="border-solid border-t-1 border-l-1 border-gray w-full">
        <div className="flex align-center justify-around mb-3">
          {[...Array(7)].map((_, index) => (
            <Skeleton key={index} className="rounded-lg w-8 h-5 m-1" />
          ))}
        </div>
        {[...Array(5)].map((_, index) => (
          <React.Fragment key={index}>{daysRow}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CalendarSkeleton;