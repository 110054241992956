import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody
} from "@nextui-org/react";
import FormAddPassengers from "./../reservations/FormAddPassengers";
import FormCrudTravels from "./FormCrudTravels";

export default function ModalTravelsData({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  travelAction,
  travelSelected,
  onlyPassengers,
  showReservations
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
      className="overflow-visible"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titileModel}
            </ModalHeader>
            <ModalBody className="overflow-visible">
              {!onlyPassengers &&
                <div>
                  <FormCrudTravels
                    travel={travelSelected}
                    readOnly={fieldFlagReadOnly}
                    travelAction={travelAction}
                    onClose={onClose}
                  />
                </div>
              }
              { onlyPassengers &&
                <div>
                  <FormAddPassengers
                    trip={travelSelected.id}
                    readOnly={false}
                    showReservations={showReservations}
                    onClose={onClose}
                  />
                </div>
              }
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
