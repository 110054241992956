import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody
} from "@nextui-org/react";
import FormAddPassengers from "./FormAddPassengers";

export default function ModalReservationData({
  isOpen,
  onClose,
  titileModel,
  fieldFlagReadOnly,
  reservationAction,
  reservationSelected,
  tripId,
  onlyPassengers,
  showReservations
}) {
  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      onClose={onClose}
      placement={"center"}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titileModel}
            </ModalHeader>
            <ModalBody>
              {!onlyPassengers &&
                <div>
                  <FormAddPassengers
                    readOnly={false}
                    trip={tripId}
                    showReservations={showReservations}
                    onClose={onClose}
                  />
                </div>
              }
              { onlyPassengers &&
                <div>
                  <FormAddPassengers
                    reservation={reservationSelected}
                    readOnly={true}
                    trip={tripId}
                    showReservations={showReservations}
                    onClose={onClose}
                  />
                </div>
              }
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
