import React from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell
} from "@nextui-org/react";
import TopContentTableUsers from "./TopContentTableUsers";
import BottomContentTableUsers from "./BottomContentTableUsers";
import {INITIAL_VISIBLE_COLUMNS, COLUMNS_USERS, STATUS_OPTIONS_USERS} from "../../constants/index"
import CellUsers from "./CellUsers";
import { useSessions } from "../../hooks/useSessions";

export default function TableUsers({
  setUserSelected,
  setValueUserAction,
  onOpen,
  onDeleteOpen,
  onSendMailOpen,
}) {
  const { users } = useSessions();
  const [filterValue, setFilterValue] = React.useState("");
  const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = React.useState(
    new Set(INITIAL_VISIBLE_COLUMNS)
  );
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDescriptor, setSortDescriptor] = React.useState({
    column: "age",
    direction: "ascending",
  });
  

  const [page, setPage] = React.useState(1);

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return COLUMNS_USERS;

    return COLUMNS_USERS.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = React.useMemo(() => {
    let filteredUsers = [...users];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) => {
        const hasFirstNameFilter = user.firstname && user.firstname.toLowerCase().includes(filterValue.toLowerCase());
        const hasLastNameFilter = user.lastname && user.lastname.toLowerCase().includes(filterValue.toLowerCase());
        const hasEmailFilter = user.email && user.email.toLowerCase().includes(filterValue.toLowerCase());

        return hasFirstNameFilter || hasLastNameFilter || hasEmailFilter;
      });
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== STATUS_OPTIONS_USERS.length
    ) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(statusFilter).includes(user.status)
      );
    }

    return filteredUsers;
  }, [users, filterValue, statusFilter]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);


  const onNextPage = React.useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = React.useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const editOrCreateUser = React.useCallback((userActionFlag, user) => {
    setValueUserAction(userActionFlag);
    setUserSelected(user);
    onOpen();
  });

  const deleteUser = React.useCallback((user) => {
    setUserSelected(user);
    onDeleteOpen();
  });

  const onSendMail = React.useCallback((user) => {
    setUserSelected(user);
    onSendMailOpen();
  });
  
  return (
    <>
      <div className="users__total">
        <TopContentTableUsers
          editOrCreateUser={editOrCreateUser}
          onClear={onClear}
          onSearchChange={onSearchChange}
          filterValue={filterValue}
        />
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="sm:hidden users__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No users found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellUsers
                      user={item}
                      columnKey={columnKey}
                      editOrCreateUser={editOrCreateUser}
                      deleteUser={deleteUser}
                      onSendMail={onSendMail}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Table
          aria-label=""
          isHeaderSticky
          bottomContentPlacement="outside"
          className="hidden sm:flex gap-4 users__table"
          selectedKeys={selectedKeys}
          sortDescriptor={sortDescriptor}
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={COLUMNS_USERS}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"No users found"} items={sortedItems}>
            {(item) => (
              <TableRow key={item.id}>
                {(columnKey) => (
                  <TableCell>
                    <CellUsers
                      user={item}
                      columnKey={columnKey}
                      editOrCreateUser={editOrCreateUser}
                      deleteUser={deleteUser}
                      onSendMail={onSendMail}
                    />
                  </TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
        <BottomContentTableUsers
          pages={pages}
          page={page}
          setPage={setPage}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
        />
      </div>
    </>
  );
}
