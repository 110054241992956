import React, {useEffect} from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Select,
  SelectItem,
  getKeyValue,
} from "@nextui-org/react";
import { formatDate, formatDateWithoutTime } from "../../utilities/dateHelpper";

import { COLUMNS_PAYMENTS, COLUMNS_TYPE_PAYMENTS } from "../../constants/index"

import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";


import usePayments from "../../hooks/payments/usePayments";



const ModalAddPayments = ({isOpen, onOpenChange, reservationSelected = {}, onClose, isCreate, setReservationSelected}) => {

  const {
    onFetchPayments,
    payments,
    onCreateNewPayment,
    onSetForm,
    paymentSchema,
  } = usePayments({reservationId: reservationSelected.id});


  let titlePay = isCreate ? 'Crear Pago' : 'Pagos';
  
  const handlePress = () => {
    onCreateNewPayment(onClose);
  };

  useEffect(()=>{
    if(!isOpen){
      setReservationSelected({});
    }
  },[isOpen])

  useEffect(()=>{
    if(reservationSelected && reservationSelected.id){
      onFetchPayments(reservationSelected.id)
    }
  },[reservationSelected])
  const getTypePay = (item) =>{
    if(item){
      return COLUMNS_TYPE_PAYMENTS.find(type => type.key == item.payment_type).name
    }else{
      return 'none'
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOpenChange={onOpenChange}
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              {titlePay}
            </ModalHeader>
            {isCreate &&
              <ModalBody >
                <Select label="Seleccione una forma de pago" onChange={(event) => onSetForm("paymentType", event.target.value)}>
                  <SelectItem key="cash" value="cash">
                    Efectivo
                  </SelectItem>
                  <SelectItem key="debit" value="debit">
                    Tarjeta de debito
                  </SelectItem>
                  <SelectItem key="credit" value="credit">
                    Tarjeta de credito
                  </SelectItem>
                  <SelectItem key="bankTransfer" value="bankTransfer">
                    Transferencia bancaria
                  </SelectItem>
                </Select>
                <Input
                  type="number"
                  label="Monto"
                  placeholder="0.00"
                  labelPlacement="outside"
                  onChange={(event) => onSetForm("installment", event.target.value)}
                  startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-default-400 text-small">$</span>
                    </div>
                  }
                />
              </ModalBody>
            }
            <Table aria-label="Example table with dynamic content">
            <TableHeader columns={COLUMNS_PAYMENTS}>
              {(column) => <TableColumn key={column.uid}>{column.name}</TableColumn>}
            </TableHeader>
            <TableBody items={payments}>
              {(item) => (
                <TableRow key={item.uid}>
                  {(columnKey) =>{
                    if(columnKey == 'payment_type'){
                      return <TableCell>{getTypePay(item)}</TableCell>
                    }else if(columnKey == 'created_at'){
                      return <TableCell>{formatDateWithoutTime(item)}</TableCell>
                    }else{
                      return <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                    }
                  }}
                </TableRow>
              )}
            </TableBody>
          </Table>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cerrar
              </Button>
              {isCreate &&
              <Button color="primary" onPress={handlePress}>
                Aceptar
              </Button>}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ModalAddPayments;