import {useState} from 'react'
import { userRegister, userUpdate } from '../../store/actions/sessionActions';
import { useDispatch, useSelector } from "react-redux";


export const useRegisterUserForm = () => {
  const dispatch = useDispatch();
  const [formUser, setUserForm] = useState({
    id: '',
    email:'',
    firstName:'',
    lastName: '',
    password: '',
    role: '',
  });

  const setForm = (type, value) => {
    setUserForm({
      ...formUser,
      [type]: value,
    });
  };

  const setUserSelected = (user) => {
    setUserForm({
      id: user.id ?? '',
      email: user.email ?? '',
      firstName: user.firstname ?? '',
      lastName: user.lastname ?? '',
      role: user?.user_roles?.[0]?.role ?? '',
    })
  }

  const onSendForm = (callback, mode) => {
    mode === 'create' ? dispatch(userRegister(formUser, callback)) : dispatch(userUpdate(formUser, callback))
  };

  const {
    registerLoading,
    registerError,
  } = useSelector((state) => state.session);

  return {
    formUser,
    setForm,
    onSendForm,
    setUserSelected,
    registerLoading,
    registerError,
  };
}
