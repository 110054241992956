import React from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";

const CalendarEventModalAttendees = ({ attendees }) => {
  if (attendees.length === 0) {
    return (
      <div>
        Aun no han registrado pasajeros en este viaje
      </div>
    )
  }
  return (
    <div>
      <Accordion>
      <AccordionItem
        key="1"
        aria-label="Connected devices"
        title={
          <div className="flex items-center">
            <p className="font-bold text-lg">Pasajeros</p>
          </div>
        }
        subtitle={'Click para mostrar/ocultar Pasajeros'}
      >
        <div className="mt-1">
          <Table>
            <TableHeader>
              <TableColumn>Nombre</TableColumn>
              <TableColumn>Apellido</TableColumn>
              <TableColumn>DNI</TableColumn>
            </TableHeader>
            <TableBody>
              {attendees.map((attendee, index) => (
                <TableRow key={index}>
                  <TableCell>{`${attendee.namePassenger}`}</TableCell>
                  <TableCell>{` ${attendee.surnamePassenger}`}</TableCell>
                  <TableCell>{attendee.dniPassenger}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CalendarEventModalAttendees;