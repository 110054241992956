// Vendors
import React from 'react'

// Components
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/icons/marca_marga.png';

// Custom hooks
import { useSessions } from '../../hooks/useSessions';
import { useNavigate } from "react-router-dom";

// utilities
import { getUserMenu } from '../../utilities/rolePermissionHelpper';

export default function MTNavbar() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { isLogged, onLogOut, userInformation, publicUserData } = useSessions();
  const navigate = useNavigate();
  const firstName = userInformation && userInformation.user_metadata.firstname;
  const lastName = userInformation && userInformation.user_metadata.lastname;
  const userRole = publicUserData && publicUserData.userRole;
  const menu = getUserMenu(userRole);
  const handleMenuClick = (link) => {
    setIsMenuOpen(false);
    navigate(link);
  };

  const currentLocation = window.location.pathname;
  return isLogged ? (
    <Navbar
      classNames={{
        item: [
          "flex",
          "relative",
          "h-full",
          "items-center",
          "data-[active=true]:after:content-['']",
          "data-[active=true]:after:absolute",
          "data-[active=true]:after:bottom-0",
          "data-[active=true]:after:left-0",
          "data-[active=true]:after:right-0",
          "data-[active=true]:after:h-[3px]",
          "data-[active=true]:after:rounded-[3px]",
          "data-[active=true]:after:bg-primary",
        ],
      }}
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <Link color="foreground" onClick={() => handleMenuClick("/")}>
            <p className="font-bold text-inherit">
              <img className="mb-1" src={logo} alt="logo" />
            </p>
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        {menu.map((item, index) => {
          const isActive =
            currentLocation === `/${item.link}` ||
            (item.link === "inicio" && currentLocation === "/") ||
            (item.link === "viajes" && currentLocation.includes("reservas"));
          return (
            <NavbarItem key={index} isActive={isActive}>
              <Link
                color="foreground"
                onClick={() => handleMenuClick(`/${item.link}`)}
              >
                {item.label}
              </Link>
            </NavbarItem>
          );
        })}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Dropdown>
            <DropdownTrigger>
              <strong>
                <span className="mr-1">{firstName}</span>
                <span className="mr-1">{lastName}</span>
                <span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </strong>
            </DropdownTrigger>
            <DropdownMenu>
              <DropdownItem onClick={() => handleMenuClick("reset-password")}>
                Cambiar contraseña
              </DropdownItem>
              <DropdownItem onClick={() => onLogOut()}>
                Cerrar sesi&oacute;n
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        {menu.map((item, index) => (
          <NavbarMenuItem key={`${item.link}-${index}`}>
            <Link
              color="foreground"
              className="w-full"
              onClick={() => handleMenuClick(item.link)}
              size="lg"
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  ) : null;
}
