import { distance } from "framer-motion";
import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";

const getDestinationLoading = () => ({
  type: actionTypes.DESTINATION_FETCH_LOADING,
});

const getDestinationSuccess = (data) => ({
  type: actionTypes.DESTINATION_FETCH_SUCCESS,
  payload: data,
});

const getDestinationFailure = () => ({
  type: actionTypes.DESTINATION_FETCH_FAILURE,
});

const getDestination = () => async (dispatch) => {
  dispatch(getDestinationLoading());
  let { data, error } = await supabase
    .from("destination")
    .select(
      "id,name,observations,enterprise_price,particular_price,latitud,longitud,distance"
    )
    .eq("is_deleted", false);
  if (!error) {
    dispatch(getDestinationSuccess(data));
  } else {
    dispatch(getDestinationFailure());
  }
};

const createDestinationLoading = () => ({
  type: actionTypes.DESTINATION_CREATE_LOADING,
});

const createDestinationSuccess = () => ({
  type: actionTypes.DESTINATION_CREATE_SUCCESS,
});

const createDestinationFailure = () => ({
  type: actionTypes.DESTINATION_CREATE_FAILURE,
});

const createDestination = (formData, successCallback) => async (dispatch) => {
  dispatch(createDestinationLoading());
  const { data, error } = await supabase
    .from("destination")
    .insert([
      {
        name: formData.name.trim(),
        observations: formData.observation.trim(),
        particular_price: formData.particular_price,
        enterprise_price: formData.enterprise_price,
        latitud: formData.latitud,
        longitud: formData.longitud,
        distance: formData.distance,
      },
    ])
    .select();
  if (!error) {
    dispatch(createDestinationSuccess());
    successCallback();
    dispatch(getDestination());
  } else {
    dispatch(createDestinationFailure());
  }
  
};

const updateDestination = (formData, successCallback) => async (dispatch) => {
  dispatch(createDestinationLoading());
  const { data, error } = await supabase
    .from("destination")
    .update({
      name: formData.name.trim(),
      observations: formData.observation.trim(),
      latitud: formData.latitud,
      longitud: formData.longitud,
      enterprise_price: formData.enterprise_price,
      particular_price: formData.particular_price,
      distance: formData.distance,
    })
    .eq("id", parseInt(formData.id))
    .select();
  if (!error) {
    dispatch(createDestinationSuccess());
    successCallback();
    dispatch(getDestination());
  } else {
    dispatch(createDestinationFailure());
  }
}

const deleteDestinationLoading = () => ({
  type: actionTypes.DESTINATION_DELETE_LOADING,
});

const deleteDestinationSuccess = () => ({
  type: actionTypes.DESTINATION_DELETE_SUCCESS,
});

const deleteDestinationFailure = () => ({
  type: actionTypes.DESTINATION_DELETE_FAILURE,
});


const deleteDestination = (id, successCallback) => async (dispatch) => {
  dispatch(deleteDestinationLoading());
  const { error } = await supabase
    .from("destination")
    .update({
      is_deleted: true,
    })
    .eq("id", id);
  if (!error) {
    successCallback();
    dispatch(deleteDestinationSuccess());
    dispatch(getDestination());
  } else {
    dispatch(deleteDestinationFailure());
  }
};

export { getDestination, createDestination, updateDestination, deleteDestination };
