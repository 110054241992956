import React from "react";
import {
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
} from "@nextui-org/react";
import {STATUS_COLOR_TRANSFERS, STATUS_OPTIONS_TRANSFERS, ADMIN_ROLE} from "../../constants/index"
import { VerticalDotsIcon } from "../commons/icons/VerticalDotsIcon";

import stardarVehicleAvatar from '../../assets/images/avatars/vehicle.PNG'
import disabledPeopleVehicleAvatar from '../../assets/images/avatars/vehicle_disabled_people.PNG'

  export default function CellTransfers({
    userRole,
    transfer,
    columnKey,
    editOrCreateTransfer,
    deleteTransfer,
  }) {
    const cellValue = transfer[columnKey];
    switch (columnKey) {
      case "license_plate":
        const disableAvatar = transfer.suitable_for_disabled_people
          ? disabledPeopleVehicleAvatar
          : stardarVehicleAvatar;
        return (
          <User
            avatarProps={{ radius: "lg", src: disableAvatar }}
            description={`${transfer.model} - ${transfer.year}`}
            name={`${cellValue}`}
          >
            {transfer.brand}
          </User>
        );
      case "brand":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "status":
        return (
          <Chip
            className="capitalize"
            color={STATUS_COLOR_TRANSFERS[transfer.status]}
            size="sm"
            variant="flat"
          >
            {
              STATUS_OPTIONS_TRANSFERS.find((status) => status.uid == cellValue)
                .name
            }
          </Chip>
        );
      case "capacity":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "observation":
        return (
          <div className="flex flex-col">
            <p className="text-bold text-small capitalize">{cellValue}</p>
          </div>
        );
      case "suitable_for_disabled_people":
        return (
          <div className="flex flex-col text-center">
            <p className="text-bold text-small capitalize">
              {cellValue ? "SI" : "NO"}
            </p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown>
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <VerticalDotsIcon className="text-default-300" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  onPress={() => editOrCreateTransfer("view", transfer)}
                >
                  Ver
                </DropdownItem>
                {userRole === ADMIN_ROLE && (
                  <DropdownItem
                    onPress={() => editOrCreateTransfer("edit", transfer)}
                  >
                    Editar
                  </DropdownItem>
                )}
                {userRole === ADMIN_ROLE && (
                  <DropdownItem onPress={() => deleteTransfer(transfer)}>
                    Eliminar
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }
  