import supabase from "../../config/supabaseConfig";
import actionTypes from "../../constants/actionTypes";
import toast from "react-hot-toast";

const getTransfersLoading = () => ({
  type: actionTypes.TRANSFERS_FETCH_LOADING,
});

const getTransfersSuccess = (data) => ({
  type: actionTypes.TRANSFERS_FETCH_SUCCESS,
  payload: data,
});

const getTransfersFailure = () => ({
  type: actionTypes.TRANSFERS_FETCH_FAILURE,
});

const getTransfers = () => async (dispatch) => {
  dispatch(getTransfersLoading());
  let { data, error } = await supabase
    .from("vehicle")
    .select(
      `id,
    license_plate,
    brand,
    model,
    year,
    capacity,
    status,
    observations,
    suitable_for_disabled_people`
    )
    .eq("is_deleted", false);
  if (!error) {
    dispatch(getTransfersSuccess(data));
  } else {
    dispatch(getTransfersFailure());
  }
};

const createTransferLoading = () => ({
  type: actionTypes.TRANSFER_CREATE_LOADING,
});

const createTransferSuccess = () => ({
  type: actionTypes.TRANSFER_CREATE_SUCCESS,
});

const createTransferFailure = () => ({
  type: actionTypes.TRANSFER_CREATE_FAILURE,
});

const createTransfer = (formData, successCallback) => async (dispatch) => {
  dispatch(createTransferLoading());
  const { error } = await supabase
    .from("vehicle")
    .insert([
      {
        license_plate: formData.licensePlate.trim(),
        brand: formData.brand.trim(),
        model: formData.model.trim(),
        year: formData.year,
        capacity: formData.capacity,
        status: formData.status.trim(),
        observations: formData.observations.trim(),
        suitable_for_disabled_people: formData.suitableForDisabledPeople,
      },
    ])
    .select();
  if (!error) {
    dispatch(createTransferSuccess());
    successCallback();
    dispatch(getTransfers());
  } else {
    dispatch(createTransferFailure());
  }
};

const updateTransfer = (formData, successCallback) => async (dispatch) => {
  dispatch(createTransferLoading());
  const { error } = await supabase
    .from("vehicle")
    .update({
      license_plate: formData.licensePlate.trim(),
      brand: formData.brand.trim(),
      model: formData.model.trim(),
      year: formData.year,
      capacity: formData.capacity,
      status: formData.status.trim(),
      observations: formData.observations.trim(),
      suitable_for_disabled_people: formData.suitableForDisabledPeople,
    })
    .eq("id", parseInt(formData.id))
    .select();
  if (!error) {
    dispatch(createTransferSuccess());
    successCallback();
    dispatch(getTransfers());
  } else {
    dispatch(createTransferFailure());
  }
}

const deleteTrasferLoading = () => ({
  type: actionTypes.TRANSFER_DELETE_LOADING,
});

const deleteTrasferSuccess = () => ({
  type: actionTypes.TRANSFER_DELETE_SUCCESS,
});

const deleteTrasferFailure = () => ({
  type: actionTypes.TRANSFER_DELETE_FAILURE,
});


const deleteTransfer = (id, successCallback) => async (dispatch) => {
  dispatch(deleteTrasferLoading());
  const { error } = await supabase
    .from("vehicle")
    .update({
      is_deleted: true,
    })
    .eq("id", id);
  if (!error) {
    successCallback();
    dispatch(deleteTrasferSuccess());
    dispatch(getTransfers());
  } else {
    dispatch(deleteTrasferFailure());
  }
}

export { getTransfers, createTransfer, updateTransfer, deleteTransfer }
