import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import exampleReducer from "./reducers/exampleReducer";
import sessionReducer from "./reducers/sessionReducer";
import destinationReducer from "./reducers/destinationReducer";
import transferReducer from "./reducers/transferReducer";
import driverReducer from "./reducers/driverReducer";
import travelsReducer from './reducers/travelsReducer'
import reservationReducer from "./reducers/reservationsReducer";
import eventReducer from "./reducers/eventsReducer";
import paymentsReducer from "./reducers/paymentsReducer";
import reportsReducer from "./reducers/reportsReducer";

const rootReducer = combineReducers({
  example: exampleReducer,
  session: sessionReducer,
  drivers: driverReducer,
  destinations: destinationReducer,
  transfers: transferReducer,
  travels: travelsReducer,
  reservations: reservationReducer,
  events: eventReducer,
  payments: paymentsReducer,
  reports: reportsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
