import { useState } from "react";
import {
  getDestination,
  createDestination,
  updateDestination,
  deleteDestination,
} from "../../store/actions/destinationActions";
import { useDispatch, useSelector } from "react-redux";

const useDestinations = (destination) => {
  const dispatch = useDispatch();
  const [destinationSchema, setDestinationSchema] = useState({
    id: destination ? destination.id : "",
    name: destination ? destination.name : "",
    observation: destination ? destination.observations : "",
    particular_price: destination ? destination.particular_price : "",
    enterprise_price: destination ? destination.enterprise_price : "",
    longitud: destination ? destination.longitud : "",
    latitud: destination ? destination.latitud : "",
    distance: destination ? destination.distance: "",
  });

  const onSetForm = (type, value) => {
    setDestinationSchema({
      ...destinationSchema,
      [type]: value,
    });
  };

  const destinationState = useSelector((state) => state.destinations);
  const {
    destinations,
    loadingDestination,
    errorDestination,
    loadingCreatingDestination,
    errorCreatingDestination,
    loadingDeletingDestination,
    errorDeletingDestination,
  } = destinationState;

  const fetchDestination = () => dispatch(getDestination());

  const onCreateNewDestination = (successCallback) =>
    dispatch(createDestination(destinationSchema, successCallback));

  const onUpdateDestination = (successCallback) =>
    dispatch(updateDestination(destinationSchema, successCallback));

  const onDeleteDestination = (id, successCallback) =>
    dispatch(deleteDestination(id, successCallback));

  return {
    loadingDestination,
    destinations,
    fetchDestination,
    onCreateNewDestination,
    onSetForm,
    loadingDestination,
    loadingCreatingDestination,
    errorDestination,
    errorCreatingDestination,
    destinations,
    destinationSchema,
    onUpdateDestination,
    onDeleteDestination,
    loadingDeletingDestination,
    errorDeletingDestination
  };
};

export default useDestinations;
