import React, {useState} from 'react'
import { useDisclosure} from "@nextui-org/react";


export const useCalendarModal = () => {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const [modalInfo, setModalInfo] = useState(null);

  const onOpenModal = (newModalInfo) => {
    setModalInfo(newModalInfo);
    onOpen(true);
  }

  return {
    isOpen,
    onOpenChange,
    onOpen: onOpenModal,
    modalInfo,
  }
}
