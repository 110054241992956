import React from "react";
import {Pagination } from "@nextui-org/react";

export default function BottomContentTableTravels({
  pages,
  page,
  setPage,
  onPreviousPage,
  onNextPage,
}) {
  return (
    <div className="py-2 px-2 flex justify-center items-center">
      <Pagination
        isCompact
        showControls
        showShadow
        color="primary"
        page={page}
        total={pages}
        onChange={setPage}
      />
    </div>
  );
}
